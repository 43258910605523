import React from 'react'
import useSettings from 'hooks/useSettings'

export default function InfoLibro({ titulo = {}, profesor = '', grupo = {} }) {

    const { portada, nombre, semestre, autor } = titulo
    const [getEnvDomainResources] = useSettings()

    return (
        <div className="component__infoLibro">
            <div className="portada-img">
                <img src={getEnvDomainResources() + portada} alt="Portada libro" />
            </div>
            <div className="details">
                <h2>{nombre} - <span>{autor}</span></h2>
                <span>{`Profesor: ${profesor}`}</span>
                <span>{`Grupo: ${grupo.nombre}`}</span>
            </div>
        </div>
    )
}

export const LoadingInfoLibro = () => {
    return (
        <div className="loading__infoLibro">
            <div className="portada-img shimmer">

            </div>
            <div className="details">
                <div className='h2 shimmer'></div>
                <div className='h2 shimmer'></div>
                <div className='h2 shimmer'></div>
                <div className='span shimmer'></div>
                <div className='span shimmer'></div>
            </div>
        </div>
    )
}
