import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'

import { useMutation } from '@apollo/client'
import { REGISTER_TOKEN } from 'graphql/Mutations/mutation.alumno'
import ShowError from 'components/Helpers/Form/ShowError'
import { LoaderSection } from 'components/Helpers/Loader/Loader'
import { GET_PORTAFOLIO_EVIDENCIAS } from 'graphql/Queries/queries.alumno'
import toast from 'react-hot-toast'

export const RegistroLibro = ({ close }) => {

    const [stateError, setStateError] = useState('')

    const [registerToken, { loading }] = useMutation(REGISTER_TOKEN, {
        onCompleted: ({ registerToken }) => {
            if(registerToken) {
                toast.success('Nuevo libro registrado.')
                close()
            }
        },
        onError: (error) => setStateError(error.message),
        refetchQueries: [
            { query: GET_PORTAFOLIO_EVIDENCIAS }
        ]
    })

    const handleSubmit = (values) => {
        registerToken({ variables: values })
    }

    return (
        <div className="custom__modal">
            {
                loading ? <LoaderSection /> :
                    <>
                        <div className="title">
                            <div className="text">
                                <h2>Registrar nuevo token</h2>
                                <p>Este token es proporcionado por tu profesor.</p>
                            </div>
                            <button className="close" onClick={close}>
                                &times;
                            </button>
                        </div>
                        <div className="content">

                            <Formik
                                initialValues={{
                                    token: ''
                                }}
                                onSubmit={handleSubmit}
                            >
                                <Form className="Form">
                                    <div className="input">
                                        <label htmlFor="token">Token</label>
                                        <Field type="text" name="token" id="token" />
                                    </div>
                                    <div className="buttons-group right">
                                        <button className="btn primary small" type="submit">Aceptar</button>
                                    </div>
                                    {
                                        stateError ? <ShowError message={stateError} /> : null
                                    }
                                </Form>

                            </Formik>
                        </div>
                    </>
            }

        </div>
    )
}
