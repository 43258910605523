import React from 'react'

export const WrongLetters = ({wrongLetters}) => {
    return (
    <div className="component__wrongLetters noselect">
      <div>
        {
          wrongLetters.length > 0 && 
          <p>Letras incorrectas</p>
        }
        {
          wrongLetters
          .map((letter, i) => <span key={i}>{letter}</span>)
          .reduce((prev, curr) => prev === null ? [curr] : [prev,curr], null)
          }
      </div>
    </div>
    )
}
