import React, { useContext } from 'react'
import { Helmet } from "react-helmet"
//Components
import { Recurso } from './recurso'
import { Book } from './Book'
import { LoaderSection } from 'components/Helpers/Loader/Loader'
//Redux
import { useSelector } from 'react-redux'
//GraphQL
import { useQuery } from '@apollo/client'
import { GET_MY_RECURSOS_BY_ACADEMIA, GET_LIBRO_BY_ACADEMIA } from 'graphql/Queries/queries.profesor'

import './recursos.css'

export const Recursos = () => {

    const { idAcademia } = useSelector(state => state.academia)

    const { data: recursos, loading, error } = useQuery(GET_MY_RECURSOS_BY_ACADEMIA, {
        variables: {
            idAcademia: idAcademia
        }
    })
    const { data: libroDigital, loading: loadingLibro, error: errorLibro } = useQuery(GET_LIBRO_BY_ACADEMIA, {
        variables: {
            idAcademia: idAcademia
        }
    })

    if (!idAcademia) return <LoaderSection />
    if (loading) return <LoaderSection />
    if (loadingLibro) return <LoaderSection />
    if (error) return <p>Oops! Ocurrio un error al cargar el libro</p>
    if (errorLibro) return <p>Oops! Ocurrio un error al cargar el libro</p>
    let resources = recursos.getMyAcademiasByProfesor[0].titulo.recursos
    let libro = libroDigital.getIndiceByTituloOrAcademia[0]

    return (
        <div className="component__recursos">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Recursos - Ktdra Digital</title>
            </Helmet>
            <div className="wrapper_recursos">
                <div>
                    <div className="title__content">
                        <h2>Recursos disponibles</h2>
                        <p>Accede a los ultimos recursos del libro.</p>
                    </div>
                    <div className="container-items">
                        {
                            resources.map((recurso, index) => (
                                <Recurso key={index} recurso={recurso} />
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="wrapper_recursos">
                <div>
                    <div className="title__content">
                        <h2>Libro digital</h2>
                        <p>Accede al libro digital en cualquier momento.</p>
                    </div>
                    <div className="container-items">
                        <Book libro={libro} />
                    </div>
                </div>
            </div>
        </div>
    )
}

