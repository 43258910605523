import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { REGISTER_VALIDATION_SCHEMA } from 'utils/validationSchemas'

//GraphQL
import { useMutation } from '@apollo/client'
import { REGISTRO_PROFESORES } from 'graphql/Mutations/mutation.registro'
import ShowError from 'components/Helpers/Form/ShowError'
import { LoaderSection } from 'components/Helpers/Loader/Loader'

import EXITO_SVG from 'assets/images/icons/exito.svg'
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai'

export const FormRegister = ({ estadosPlanteles, idLibro }) => {

    const [estados, setEstados] = useState([])
    const [planteles, setPlanteles] = useState([])
    const [estadoSelected, setEstadoSelected] = useState('')
    const [errorRegister, setErrorRegister] = useState(null)
    const [successRegister, setSuccessRegister] = useState(null)
    const [viewPassword, setViewPassword] = useState(false)

    const handleSelectEstado = (e) => setEstadoSelected(e.target.value)

    useEffect(() => {
        if(estadosPlanteles){
            estadosPlanteles.map(estado => (setEstados(estados => [...estados, estado.estado[0]])))
            setEstadoSelected(estadosPlanteles[0]?.estado[0]?._id)
        }
    }, [estadosPlanteles])

    useEffect(() => {
        if(estadosPlanteles){
            setPlanteles(estadosPlanteles.filter( plantel => plantel.estado[0]._id === estadoSelected))
        }
    }, [estadosPlanteles, estadoSelected])
    

    const showPassword = () => {
        const show = !viewPassword
        setViewPassword(show)
    }

    const [registerProfesor, { loading: loadingRegisterProfesor }] = useMutation(REGISTRO_PROFESORES, {
        onCompleted: () => {
            setSuccessRegister(true)
        },
        onError: (error) => {
            setErrorRegister(error.message)
        }
    })

    if (loadingRegisterProfesor) return <LoaderSection />
    if (successRegister) return <FormSucess />

    const submitRegistro = (values) => {
        values.idLibro = idLibro
        registerProfesor({
            variables: {
                email: values.email,
                password: values.password,
                nombres: values.name,
                primerApellido: values.a_paterno,
                segundoApellido: values.a_materno,
                _idTitulo: values.idLibro,
                _idPlantel: values.plantel
            }
        })
    }

    return (
        <div className="component__formLogin">
            <Formik
                initialValues={{
                    name: '',
                    a_paterno: '',
                    a_materno: '',
                    email: '',
                    password: '',
                    plantel: planteles?.[0]?.planteles[0]._id,
                }}
                validationSchema={REGISTER_VALIDATION_SCHEMA}
                onSubmit={submitRegistro}
                autoComplete="off"
            >
                <Form className="Form" autoComplete='off'>
                    <div className="input">
                        <label htmlFor="name">Nombre(s)</label>
                        <Field type="text" id="name" name="name" />
                        <ErrorMessage
                            name="name"
                            component="span"
                            className="msg-error"
                        />
                    </div>
                    <div className="input-group">
                        <div className="input">
                            <label htmlFor="a_paterno">Apellido paterno</label>
                            <Field type="text" id="a_paterno" name="a_paterno" />
                            <ErrorMessage
                                name="a_paterno"
                                component="span"
                                className="msg-error"
                            />
                        </div>
                        <div className="input">
                            <label htmlFor="a_materno">Apellido materno</label>
                            <Field type="text" id="a_materno" name="a_materno" />
                            <ErrorMessage
                                name="a_materno"
                                component="span"
                                className="msg-error"
                            />
                        </div>
                    </div>
                    <div className="input">
                        <label htmlFor="email">Correo electrónico</label>
                        <Field type="text" id="email" name="email" />
                        <ErrorMessage
                            name="email"
                            component="span"
                            className="msg-error"
                        />
                    </div>
                    <div className="input">
                        <label htmlFor="password">Contraseña</label>
                        <div className="input-password">
                            <Field type={viewPassword ? "text" : "password"} id="password" name="password"  />
                            <button type="button" onClick={showPassword}>{ viewPassword === true ? <AiFillEyeInvisible /> : <AiFillEye /> }</button>
                        </div>
                        <ErrorMessage
                            name="password"
                            component="span"
                            className="msg-error"
                        />
                    </div>
                    <div className="input">
                        <label htmlFor="estado">Estado</label>
                        <Field as="select" name="estado" onChange={handleSelectEstado}>
                        {
                            estados.map((estado, i) => (
                                <option key={i} value={estado._id}>{estado.nombre.toUpperCase()}</option>
                            ))
                        }
                        </Field>
                        <ErrorMessage
                            name="estado"
                            component="span"
                            className="msg-error"
                        />
                    </div>
                    <div className="input">
                        <label htmlFor="plantel">Plantel</label>
                        <Field as="select" name="plantel">
                                <option value="" selected>Selecciona un plantel</option>
                            {
                                planteles[0]?.planteles.map((plantel, i) => (
                                    <option key={i} value={plantel._id}>{plantel.nombre}</option>
                                ))
                            }
                        </Field>
                        <ErrorMessage
                            name="plantel"
                            component="span"
                            className="msg-error"
                        />
                    </div>
                    <div className="buttons-group">
                        <button className="btn primary" type="submit">Registrarme ahora</button>
                    </div>
                    {
                        errorRegister ? <ShowError message={errorRegister} /> : null
                    }                    
                </Form>
            </Formik>
        </div>
    )
}
const FormSucess = () => {
    return (
        <div className="success">
            <div className="svg"><img src={EXITO_SVG} alt="Icono de exito" /></div>
            <div className="text">
                <h2>VERIFICA TU CORREO</h2>
                <p>Te enviamos un mensaje para verificar que si eres dueño del correo.</p>
            </div>
        </div>

    )
}
