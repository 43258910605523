import React from 'react'

import './styles.css'

export default function TitleExercise({ title = '', description = '' }) {
  return (
    <div className="component__titleExercise">
        <h2>{ title }</h2>
        <span>{ description }</span>
    </div>
  )
}
