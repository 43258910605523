import React, { useState, useEffect } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import Switch from "react-switch"
import Swal from 'sweetalert2'
//GraphQL
import { useMutation } from '@apollo/client'
import { DISABLE_TEMA_BY_GRUPO } from 'graphql/Mutations/mutation.profesor'
import { GET_MY_GRUPOS_BY_ACADEMIA } from 'graphql/Queries/queries.profesor'

import 'react-accessible-accordion/dist/fancy-example.css'
import toast from 'react-hot-toast'

export const IndiceLibro = ({libro, idGrupo, temasInactivos, idAcademia, close}) => {
    
    const {hijos: unidades, nombre} = libro
    
    return (
        <div className="custom__modal">
             <div className="title">
                <div className="text">
                    <h2>Desactivar temas</h2>
                    <p>Al desactivar los tema, los alumnos no podran contestar los ejercicios.</p>
                </div>
                <button className="close" onClick={close}>
                    &times;
                </button>
            </div>
            <div className="content">
            {
                <Accordion allowZeroExpanded className="accordion__book">
                    {
                        unidades.map(unidad => {
                            const {hijos: temas} = unidad
                            return(
                                <AccordionItem key={unidad._id}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {unidad.nombre}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    {
                                        temas.map(tema => (
                                           
                                            <AccordionItemPanel key={tema._id}>
                                                <div>{tema.nombre}</div>
                                                <div className="buttons">
                                                    <Controls 
                                                        idTema={tema._id}
                                                        idGrupo={idGrupo}
                                                        idAcademia={idAcademia}
                                                        estatus={temasInactivos.includes(tema._id)}
                                                    />
                                                </div>
                                            </AccordionItemPanel>
                                        ))
                                    }
                                </AccordionItem>
                            )
                        })
                    }
                </Accordion>
            }
            </div>
        </div>
    )
}

const Controls = ({idTema, idGrupo, estatus, idAcademia}) => {

    const [disableTemaByGrupo, { loading }] = useMutation(DISABLE_TEMA_BY_GRUPO,{
        onCompleted: () => {
            let statusText = estatus === true ? 'activado' : 'desactivado'
            toast.dismiss()
            toast.success(`El tema ha sido ${statusText} correctamente.`)
        },
        onError: (error) => {
            toast.dismiss()
            toast.error(`Ocurrió un error : ${error}`)
        },
        refetchQueries: [{
                query: GET_MY_GRUPOS_BY_ACADEMIA,
                variables: {
                    _idAcademia: idAcademia
                }
            }
        ], 
    })



    const handleDisableTema = (idTema, nextChecked) => {
        disableTemaByGrupo({
            variables: {
                _idGrupo: idGrupo,
                _idTema: idTema,
                estatus: nextChecked === false ? true : false 
            }
        })
        
    }
    if(loading) toast.loading('Cargando...')

    return (
        <label className="control__checked">
            <span>{!estatus ? 'Tema activo' : 'Tema inactivo'}</span>
            {
                !loading && 
                <Switch
                    width={44}
                    height={20}
                    checked={!estatus}
                    onChange={() => {handleDisableTema(idTema, estatus)}}
                />
            }
        </label>
    )
}