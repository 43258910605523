
import React, { useEffect, useState } from 'react'
import Select from 'react-select'

export default function SelectExercise({ options = [], onChange, started = false }) {

    const [placeHolder, setPlaceHolder] = useState('Selecciona un ejercicio')
    useEffect(() => {
        if (options.length <= 0) setPlaceHolder('Sin ejercicios')
        else setPlaceHolder('Selecciona un ejercicio')
    }, [options])

    return (
        <div className="select">
            <span>Lista de Ejercicios</span>
            <Select options={options} placeholder={placeHolder}
                onChange={onChange} isSearchable={false} isDisabled={started} />
        </div>
    );
}
