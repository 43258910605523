import React from 'react';
import Popup from 'reactjs-popup';
import { useParams } from 'react-router';

//Components
import { PresentationLateral } from './PresentationLateral';
import { Error } from 'components/Error/Error.jsx';
import { FormRegister } from './FormRegister';
import { ModalLogin } from './ModalLogin';
import { LoaderPage } from 'components/Helpers/Loader/Loader';
//Hooks
import { useBookById, useListPlantelesBySubsistema } from './hooks';

import './profesor.css';
import { Fag } from 'components/Profesor/Header/Configuracion';

export const RegisterProfesor = () => {
	let { idLibro } = useParams();

	const [book, loading, error] = useBookById(idLibro);
	const [
		estadosPlanteles,
		loadingEstadosPlanteles,
		errorErrorEstadosPlanteles,
	] = useListPlantelesBySubsistema(book?.subsistema?._id);

	if (loading || loadingEstadosPlanteles) return <LoaderPage />;

	if (error || errorErrorEstadosPlanteles)
		return <Error mensaje={error.message} />;

	return (
		<div className='component__loginProfesor'>
			<div className='wrapper_presentation'>
				<PresentationLateral
					portada={`${process.env.REACT_APP_DOMAIN_RESOURCE + book.portada}`}
				/>
			</div>
			<div className='wrapper_form'>
				<div>
					<div className='title'>
						<h2>¡Bienvenido Profesor@!</h2>

						<p>
							¿Ya tienes una cuenta? Ingresa tu cuenta y se agregara el libro a
							tu cuenta.
							<Popup
								className='modal'
								trigger={<button>Ingresar</button>}
								modal
								position='right center'
							>
								{close => (
									<ModalLogin
										estadosPlanteles={estadosPlanteles}
										idLibro={book?._id}
										close={close}
									/>
								)}
							</Popup>
						</p>
					</div>
					<div className='form'>
						<FormRegister
							estadosPlanteles={estadosPlanteles}
							idLibro={book?._id}
						/>
						<span>
							Al registrarme acepto las <a>condiciones generales de uso</a>
						</span>
					</div>
					<Popup
						className='modal'
						trigger={<a>Ayuda y asistencia</a>}
						modal
						position='right center'
					>
						{close => <Fag close={close} />}
					</Popup>
				</div>
			</div>
		</div>
	);
};
