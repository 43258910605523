import React, { useEffect, useState } from 'react'

import './styles.css'


export default function Button ({ text = '', disabled = false, loading = false, onClick, type = 'button', 
                                color = 'primary', ref }) {

    const [isDisabled, setIsDisabled] = useState(false)
    const [className, setClassName] = useState(`component__button ${color}`)

    useEffect(() => {
        if (loading) {
            setClassName(`component__button ${color} loading`)
        }
        else {
            setClassName(`component__button ${color}`)
        }
    }, [loading])

    useEffect(() => {
        if (disabled) setIsDisabled(true)
        else setIsDisabled(false)
    }, [disabled])

  return (
        <button className={className}
                disabled={isDisabled}
                onClick={onClick}
                ref={ref}
                type={type}>
            { text }
        </button>
  )
}

export const ButtonText = ({ text = '', disabled = false, loading = false, onClick, type = 'button', color = 'primary' }) => {
   
    const [className, setClassName] = useState(`component__buttonText ${color}`)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        if (loading) {
            setClassName(`component__buttonText ${color} loading`)
            setIsDisabled(true)
        }
        else {
            setClassName(`component__buttonText ${color}`)
            setIsDisabled(false)
        }
    }, [loading])

    useEffect(() => {
        if (disabled) setIsDisabled(true)
        else setIsDisabled(false)
    }, [disabled])

    return (
        <button className={className}
                disabled={isDisabled}
                onClick={onClick}
                type={type}>
            { text }
        </button>
  )
}
