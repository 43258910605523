import React, { useEffect, useState } from 'react'
//Components
import { BombaTiempo } from './BombaTiempo'
import SelectExercise from './SelectExercise'
import BeforeMessage from './BeforeMessage'
import ContainerExercise from './ContainerExercise'
//Hooks
import { useDataExerciseById } from './hooks'

import './styles.css'


export const Ejercicios = ({ ejercicios = [], onResetListExercises }) => {

    const [selectedEjercicio, setSelectedEjercicio] = useState('')
    const [stateExercise, setStateExercise] = useState({
        started: false,
        finalized: false,
        isTimer: false,
        time: null,
        isResource: false,
        url: false,
        typeResource: false
    })

    const [dataExercise, loadingExercise, errorExercise] = useDataExerciseById(selectedEjercicio)

    const optionsExercise = ejercicios.map(ejercicio => ({ value: ejercicio._id, label: `${ejercicio.nombre} ${ejercicio.numero}` }))

    useEffect(() => {
        if (dataExercise._id) {
            setStateExercise({
                started: false,
                finalized: false,
                isTimer: dataExercise?.tiempoS > 0 ? true : false,
                time: dataExercise?.tiempoS,
                isResource: dataExercise?.recursos[0] ? true : false,
                url: dataExercise?.recursos[0]?.url ?? null,
                latex: dataExercise?.recursos[0]?.latex ?? null,
                typeResource: dataExercise?.recursos[0]?.tipo ?? ''
            })
        }
    }, [dataExercise])

    const handleSelectExercise = (option) => setSelectedEjercicio(option.value)


    const onFinalizeTimer = () => setStateExercise(stateExercise => ({ ...stateExercise, finalized: true }))

    const handleStartExercise = () => {
        setStateExercise(stateExercise => ({ ...stateExercise, started: true, isResource: false, url: null }))
    }

    const handleCancelExercise = () => {
        setStateExercise({
            started: false,
            finalized: false,
            isTimer: false,
            isResource: false,
            url: false,
            latex: false,
            typeResource: false
        })
        setSelectedEjercicio(null)
        onResetListExercises()
    }

    return (
        <div className="component__ejercicios">

            <div className="header">
                <SelectExercise options={optionsExercise} onChange={handleSelectExercise} started={selectedEjercicio} />
                <BombaTiempo onFinalize={onFinalizeTimer} stateExercise={stateExercise} />
            </div>

            <BeforeMessage onStart={handleStartExercise} loading={loadingExercise}
                onCancel={handleCancelExercise} selectedExercise={selectedEjercicio}
                stateExercise={stateExercise} lengthExercises={ejercicios.length} />

            <ContainerExercise dataExercise={dataExercise} loading={loadingExercise}
                error={errorExercise} stateExercise={stateExercise} onReset={handleCancelExercise} />
        </div>
    )
}
