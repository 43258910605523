import React from 'react'

import { RiMessage3Fill } from 'react-icons/ri'
import './styles.css'

export const Publicaciones = () => {
    return (
        <div className="component__publicaciones">
            <h2>Proximamente</h2>
            <RiMessage3Fill />
        </div>
    )
}
