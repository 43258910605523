import React from 'react'
import { TiWarning } from 'react-icons/ti'
import './style.css'

export const ShowError = ({message}) => {
    return (
        <div className="component__showError">
            <div className="content-error">
                <div className="icon"><TiWarning /></div>
                <div className="message">{message}</div>
            </div>
        </div>
    )
}
