import React, { useEffect, useState } from 'react'

import TitleExercise from '../TitleExercise'

import { AnswerBox } from './AnswerBox'
import { useCalificacion } from './hooks'
import QuestionBox from './QuestionBox'


import './styles.css'
import Button from 'components/common/Button'

export const Quiz = ({ data, finalized = false, onFinalize }) => {

    const [dataQuestion, setDataQuiestion] = useState({
        _id: '',
        question: '',
        answers: [],
        sizeCorrects: 0,
        recursos: false
    })

    const [indexQuestion, setIndexQuestion] = useState(0)
    const [stateSelected, setStateSelected] = useState([])
    const [optionsChosen, setOptionsChosen] = useState([])

    const [setCalificaciones] = useCalificacion(data)

    useEffect(() => {
        if (data[indexQuestion]) {
            const { pregunta, respuestas, recursos } = data[indexQuestion]
            let x = 0
            respuestas.map(e => (e.correcta === true ? x++ : null))
            setDataQuiestion(
                {
                    question: pregunta,
                    answers: respuestas,
                    sizeCorrects: x,
                    recursos: recursos
                })
        }
    }, [indexQuestion])

    useEffect(() => {
        if (finalized) onFinalize(setCalificaciones(optionsChosen))
    }, [finalized])


    const alpha = Array.from(Array(26)).map((e, i) => i + 65)

    const handleNextExercise = () => {
        setIndexQuestion(indexQuestion + 1)
        setStateSelected([])
        setOptionsChosen(stateOptionsSelected => [...stateOptionsSelected, stateSelected])
    }

    useEffect(() => {
        if (indexQuestion === data.length) {
            onFinalize(setCalificaciones(optionsChosen))
        }
    }, [indexQuestion])

    const setSelectAnswer = (value) => {
        if (stateSelected.length < dataQuestion.sizeCorrects)
            setStateSelected(data => [...data, value])
        else {
            stateSelected.shift()
            setStateSelected(data => [...data, value])
        }
    }


    return (
        <div className="component__quiz">
            <TitleExercise title='Cuestionario' description='Contesta correctamente las preguntas que se te presenta.' />
            <div className="wrapper__questions">
                <div className="content">
                    <QuestionBox numQuestion={indexQuestion + 1}
                        maxQuestions={data.length}
                        resource={dataQuestion.recursos[0]?.url}
                        questionText={dataQuestion.question} maxAnswers={dataQuestion.sizeCorrects} />

                    <div className="questions">
                        {
                            dataQuestion.answers.map((e, i) => {
                                const alphabet = alpha.map((i) => String.fromCharCode(i))
                                return <AnswerBox
                                    key={i}
                                    number={alphabet[i]}
                                    answer={e}
                                    setSelected={setSelectAnswer}
                                    itemsSelected={stateSelected} />
                            })
                        }
                    </div>
                    <div className="buttons_exercises">
                        {stateSelected.length === dataQuestion.sizeCorrects && <Button text='Siguiente' onClick={handleNextExercise} />}
                    </div>
                </div>
            </div>
        </div>
    )
}
