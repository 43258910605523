import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { useMutation, useQuery } from '@apollo/client'
import { GET_MY_GRUPOS_BY_ACADEMIA } from 'graphql/Queries/queries.profesor'
import { SET_PROCESS_TEMA } from 'graphql/Mutations/mutation.profesor'
import { GET_ALL_EXERCISES_BY_INDICE } from 'graphql/Queries/queries.ejercicios'

import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useOptionsSelect } from './hooks'
import { ListEjercicios } from './ListEjercicios'
import { Spinner } from 'components/Helpers/Loader/Spinner'
import './styles.css'

export const TemaEnProceso = ({ data, close }) => {

    const { idAcademia } = useSelector(state => state.academia)
    const [valueSelected, setValueSelected] = useState('')
    const [stateExercises, setStateExercises] = useState([])
    const [getOptions, defaultValue] = useOptionsSelect(data.libro, data.inProcess[0], data.temasInactivos)

    const [setTemaInProcessByGrupo] = useMutation(SET_PROCESS_TEMA, {
        onCompleted: () => {
            toast.success(`El tema ha sido establecido como en proceso.`)
        },
        onError: (error) => {
            toast.success(`Ops! Ocurrio un error : ${error.message}`)
            close()
        },
        refetchQueries: [
            {
                query: GET_MY_GRUPOS_BY_ACADEMIA,
                variables: { _idAcademia: idAcademia }
            },
            {
                query: GET_ALL_EXERCISES_BY_INDICE,
                variables: { _idIndice: defaultValue.value }
            }
        ]
    })

    const { data: getAllExercises, loading } = useQuery(GET_ALL_EXERCISES_BY_INDICE, {
        variables: { _idIndice: defaultValue.value }
    })

    useEffect(() => {
        if (getAllExercises?.getEjerciciosByIndice || !loading)
            setStateExercises(getAllExercises?.getEjerciciosByIndice ?? [])
    }, [getAllExercises, loading])

    const handleOnSubmit = () => {
        setTemaInProcessByGrupo({
            variables: {
                _idGrupo: data.idGrupo,
                _idTema: valueSelected
            }
        })
    }

    return (
        <div className="component__temaEnProceso custom__modal maxW768px">
            <div className="title">
                <div className="text">
                    <h2>Asignar tema en proceso</h2>
                </div>
                <button className="close" onClick={close}>
                    &times;
                </button>
            </div>
            <div className="content">
                <div>
                    <span>Selecciona un tema:</span>
                    <Select
                        options={getOptions}
                        isSearchable={false}
                        onChange={e => { setValueSelected(e.value) }} />
                    <div className="wrapper-button">
                        <button onClick={handleOnSubmit} className="__btnCustom">Aceptar</button>
                    </div>

                    <div className="wrapper-process">
                        <div className="text">
                            {
                                loading ? <Spinner /> : <>
                                    <span>Tema en proceso: </span>
                                    <p>{defaultValue.label}</p>
                                </>
                            }
                        </div>
                    </div>
                    <div className="wrapper-list scroll-desing">
                        {
                            loading ? <Spinner /> : <ListEjercicios data={stateExercises} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
