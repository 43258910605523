import React from 'react'
import Button from 'components/common/Button'
import Lottie from 'react-lottie'

import useMostrarCalificacion from './hooks'
import './styles.css'

export const MostrarCalificacion = ({ value = 0, onReset }) => {

    const [setLottie] = useMostrarCalificacion()
    const options = setLottie(value)

    return (
        <div className="component__mostrarCalificacion">
            <div className="content">
                <Lottie className="lottie" options={options} height={350} width={350} />
                <div className="text">
                    <h3>Tu calificación fue de : { value > 0 ? value.toFixed(2) : value}</h3>
                    <Button text='Continuar' onClick={() => onReset()} />
                </div>
            </div>
        </div>
    )
}
