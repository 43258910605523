import React from 'react'
import { ItemTema } from './ItemTema'
import { IoBookmark } from 'react-icons/io5'
import { useScoreTag } from './hooks'

const ItemUnidad = ({ unidad }) => {

    const { nombre, calificacion, temas, num } = unidad
    const [scoreTag] = useScoreTag(calificacion)

    return (
        <div className="c__itemUnidad">
            <details className="c__itemUnidad">
                <summary>
                    <div className="title">
                        <span>{`${num}. ${nombre}`}</span>
                        <div className='total-topics'>
                            <span>
                                <IoBookmark />
                                {`${temas.length} temas`}
                            </span>
                            <span className='score' style={{color: scoreTag}}>
                                Calificación: { calificacion === 0 ? 0 : calificacion.toFixed(1) }
                            </span>
                        </div>
                    </div>
                </summary>
                <div className="items-topics">
                    {
                        temas.map((tema, i) => (<ItemTema key={i} tema={tema} />))
                    }
                </div>
            </details>
        </div>
    )
}

export const ItemUnidadLoading = () => {
    return (
        <div className="loadingItems">
            <div className="c__itemUnidad shimmer" />
            <div className="c__itemUnidad shimmer" />
        </div>
    )
}

export default ItemUnidad
