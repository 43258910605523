// Img
import ImgLogo from 'assets/images/kdtadigital-white.png';
import ImgError from 'assets/svg/Error/box.svg';
import ImgHome from 'assets/images/icons/home.svg';
// Styles
import './styles.css';

import { Link } from 'react-router-dom';

export const Error = ({ mensaje, conexion }) => {
	return (
		<div className='Error'>
			<div
				className={`${conexion ? 'Error__img--logo' : 'Error__img--conexion'}`}
			>
				<img src={conexion ? ImgLogo : ImgError} alt='imagen logo' />
			</div>
			<div className={`Error__mensaje`}> {mensaje} </div>
			<Link className='Error__btn' to={'/'}>
				Regresar al inicio <img src={ImgHome} alt='imagen home' />
			</Link>
		</div>
	);
};

Error.defaultProps = {
	mensaje: 'Mensaje de Error',
	conexion: true,
};
