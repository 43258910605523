import React from 'react'

import { FormLogin } from './FormHome'

import LOGO from 'assets/images/kdtadigital-white.png'
import WOMAN_LAPTOP from 'assets/svg/Login/Character02.svg'
import WOMAN from 'assets/svg/Login/Character07.svg'
import FIGURE from 'assets/svg/Login/Elements01.svg'

import './home.css'

export const Home = () => {
    return (
        <div className="component__home">
            <div className="header">
                <div className="navegation">
                    <div className="logo">
                        <img src={LOGO} alt="Logotipo Ktdra Digital" />
                    </div>
                </div>
                <div className="svg_figure">
                    <div className="text">
                        <h2>Conecta con tus amigos<br></br> y comienza a compartir <br></br>tus ideas</h2>
                    </div>
                    <div className="svg">
                        <img src={WOMAN_LAPTOP} className="svg-1" alt="svg"/>
                        <img src={WOMAN} className="svg-2" alt="svg"/>
                        <img src={FIGURE} className="svg-3" alt="svg"/>
                    </div>
                </div>
                
            </div>
            
       
            <div className="wrapper_form">
                <FormLogin />
            </div>

        </div>
    )
}
