import React from 'react'

import { useAsyncDebounce } from 'react-table' 
import {GoSearch} from 'react-icons/go'

export const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, }) => {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
  
    return (
      <div className="component__globalFilter">
        <GoSearch />
        <input
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Buscar...`}
          type="text"
        />
      </div>
    )
}
