import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { CREAR_GRUPO } from 'graphql/Mutations/mutation.profesor'
import { GET_MY_GRUPOS_BY_ACADEMIA } from 'graphql/Queries/queries.profesor'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import ShowError from 'components/Helpers/Form/ShowError'

import { LoaderForm } from 'components/Helpers/Loader/Loader'
import Dialogs from 'components/Helpers/Dialogs'

export const CrearGrupo = ({ close, idAcademia }) => {

    const [errorMessage, setErrorMessage] = useState(null)
    
    const [createGrupo, {loading}] = useMutation(CREAR_GRUPO, {
        onCompleted: ({createGrupo}) => {
            if(createGrupo._id){
                Dialogs.Success('Grupo creado', 'EL grupo se ha creado correctamente')
                close()
            }
        },
        onError: (error) => setErrorMessage(error.message),
        refetchQueries:[{
            query: GET_MY_GRUPOS_BY_ACADEMIA,
            variables: {
                _idAcademia: idAcademia,
            }
        }]
    })

    const handleSubmit = (values) => {
    
        createGrupo({
            variables:{
                _idAcademia: idAcademia,
                nombre: values.nombre,
                cupo: values.cupo
            }
        })
    }

    return (
        <div className="custom__modal">
           {loading ?  <LoaderForm /> : null}
            <div className="title">
                <div className="text">
                    <h2>Crear nuevo grupo</h2>
                </div>
                <button className="close" onClick={close}>
                    &times;
                </button>
            </div>
            <div className="content">
                <Formik
                    initialValues={{
                        nombre: '',
                        cupo: 1
                    }}
                    onSubmit={handleSubmit}
                >
                    <Form className="Form">
                        <div className="input">
                            <label htmlFor="nombre">Nombre del grupo</label>
                            <Field id="nombre" name="nombre" type="text" />
                        </div>
                        <div className="input">
                            <label htmlFor="cupo">Cupo del grupo</label>
                            <Field id="cupo" minLength="1" name="cupo" type="number" />
                        </div>
                        <div className="buttons-group right">
                            <button className="btn primary small" type="submit">Aceptar</button>
                        </div>
                        { errorMessage ? <ShowError message={errorMessage} /> : null }
                    </Form>
                </Formik>
            </div>
        </div>
    )
}
