import React, {useEffect} from 'react'
import { useDrag } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import useSettings from 'hooks/useSettings'
import { colorsAnswers } from './hooks'
import { suffleArrayDaya } from '../hooks'
const CardRespuesta = ({respuesta, identidadRef}) => {

    const [getEnvDomainResources] = useSettings()
    const IMG = getEnvDomainResources()
    const answer = respuesta.respuesta
    const id = respuesta._id
    const color = colorsAnswers[respuesta.index]

    useEffect(() => {
        identidadRef.res[respuesta._id].current.style.backgroundColor = `${color}`
        identidadRef.res[respuesta._id].current.style.color = `#fff`
    }, [])

    const [{ opacity }, dragRef] = useDrag(
        () =>({
            type: ItemTypes.RESPUESTA,
            item: { answer, id, color },
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult()
                if(item && dropResult) {
                    identidadRef.preg[dropResult.name].current.style.backgroundColor = item.color
                    identidadRef.preg[dropResult.name].current.style.color = '#fff'
                    identidadRef.preg[dropResult.name].current.respuestaCorrecta = item.id === dropResult.name ? true : false 
                }
            },
            collect: (monitor) => ({ opacity:monitor.isDragging() ? 0.5 : 1 })
        }),
        []
    )

    return (
        <div ref={dragRef} style={{ opacity }}>
            <div ref={identidadRef.res[respuesta._id]} className="answer card">
                <div>
                    {respuesta.respuesta && respuesta.respuesta}
                    { respuesta.imagen && <div className="image_wrapper"> <img alt="Recurso" src={IMG + respuesta.imagen} ></img></div>}
                </div>
            </div>
        </div>
    )
}

export default CardRespuesta
