import { gql } from '@apollo/client'

export const GET_TITULO_BY_ID = gql`
    query getTituloByIdRegister(
        $_id: ID!
    ){
        getTituloByIdRegister(
            _id: $_id
        ){
            _id,
            nombre
            abrev,
            subsistema{
                _id,
                nombre
            },
            portada
        }
    }
`

export const  GET_PLANTELES_BY_SUBSISTEMA= gql`
    query getPlantelesBySubsistema(
        $_id: ID!
    ){
        getPlantelesBySubsistema(
            _id: $_id
        ){
            estado {
                _id
                nombre
            }
            planteles {
                _id,
                nombre,
            }
        }
    }
`