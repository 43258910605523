import React, { useEffect, useState } from 'react'
import { useSwitchTypeExercise } from './hooks'
import { MostrarCalificacion } from '../MostrarCalificacion'
import { useSendScore } from 'hooks/useSendScore'
import { useSelector } from 'react-redux'

import './styles.css'

export default function ContainerExercise({ loading = false, error = false, dataExercise = {}, stateExercise = {}, onReset }) {

    const [scoreFinal, setScoreFinal] = useState(false)
    const { idPortEvids } = useSelector(state => state.portEvids)
    const { _id } = dataExercise
    const { started, finalized } = stateExercise
    const [switchExercise] = useSwitchTypeExercise(dataExercise, finalized, setScoreFinal)
    const [sendCalificar, stateError, loadingSendCalif] = useSendScore()

    useEffect(() => {
        setScoreFinal(false)
    }, [dataExercise])

     useEffect(() => {
        if (scoreFinal) sendCalificar(idPortEvids, _id, scoreFinal)
    }, [scoreFinal])

    if (!started) return null
    else return (
        <div className='component__containerExercise'>
            {
                scoreFinal === 0 || scoreFinal ? <MostrarCalificacion value={scoreFinal} onReset={onReset} /> : switchExercise
            }
        </div>
    )

}
