import { useQuery } from '@apollo/client';
import {
	GET_PLANTELES_BY_SUBSISTEMA,
	GET_TITULO_BY_ID,
} from 'graphql/Queries/queries.register';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useBookById = id => {
	const [book, setBook] = useState([]);
	const history = useHistory();
	const { data, loading, error } = useQuery(GET_TITULO_BY_ID, {
		variables: {
			_id: id,
		},
		skip: !id,
	});

	useEffect(() => {
		if (data) setBook(data.getTituloByIdRegister);
	}, [data]);

	return [book, loading, error];
};

export const useListPlantelesBySubsistema = id => {
	const [estadosPlanteles, setEstadosPlanteles] = useState([]);
	const {
		data,
		loading: loadingEstadosPlanteles,
		error: errorErrorEstadosPlanteles,
	} = useQuery(GET_PLANTELES_BY_SUBSISTEMA, {
		variables: {
			_id: id,
		},
		skip: !id,
	});

	useEffect(() => {
		if (data) {
			setEstadosPlanteles(data.getPlantelesBySubsistema);
		}
	}, [data]);

	return [
		estadosPlanteles,
		loadingEstadosPlanteles,
		errorErrorEstadosPlanteles,
	];
};
