import React from 'react'
import Popup from 'reactjs-popup'
import { useHistory } from 'react-router'
import { client } from 'graphql/config'
//Components
import { Configuracion } from '../Configuracion'
//Redux
import storage from 'redux-persist/lib/storage'
import { useDispatch } from 'react-redux'
import { actionLogout } from 'redux/actions/actions'
//Icons
import { BsFillGearFill } from 'react-icons/bs'
import { ImExit } from 'react-icons/im'
import { IoMdHelpCircle } from 'react-icons/io'

export const Menu = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const handleLogOut = () => {
        client.clearStore().then(() => {
            client.resetStore();
            dispatch(actionLogout())
            storage.removeItem('persist:root')
            localStorage.removeItem('token')
            history.replace('/home')
        });
    }

    return (
        <div className="component__menuProfile">
            <div className="item__list">
                <div className="item">
                    <Popup className="modal" modal closeOnDocumentClick={false} trigger={<button><BsFillGearFill /> <span>Configuración</span></button>}>
                        {close => (<Configuracion close={close} />)}
                    </Popup>
                </div>
                <div className="item">
                    <button><IoMdHelpCircle /> <span>Ayuda y asistencia</span></button>
                </div>
                <div className="item">
                    <button onClick={handleLogOut}><ImExit /> <span>Cerrar sesión</span></button>
                </div>
            </div>
        </div>
    )
}
