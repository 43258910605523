import { gql } from '@apollo/client'

export const GET_MY_INFO = gql`
    query getMyInfo{
        getMyInfo{
        _id
        email
        telefono
        matricula
        nombres
        primerApellido
        segundoApellido
        imgPerfil
        portada{
            _id
            url	
        }
        estatus
    }
}
`
export const GET_TITULO = gql`
    query getTituloByIdRegister($_id: ID!){
        getTituloByIdRegister(_id: $_id){
            _id
            nombre
            abrev
            portada
            descripcion
            semestre
            autor
            mode
            recursos{
                _id
                nombre
                tipo
                url
                esDescargable
            }
        }
    }
`

export const GET_INDICE_BY_TITULO = gql`
    query getIndiceByTituloOrAcademia ($_idTitulo: ID){
        getIndiceByTituloOrAcademia(_idTitulo: $_idTitulo){
            _id,
            nombre,
            hijos{
                _id,
                nombre,
                seccion,
                hijos{
                    _id,
                    nombre,
                    seccion,
                    recursos{
                        url
                    }
                }
            }
        }
    }
`