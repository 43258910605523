import { useQuery } from '@apollo/client'
import { GET_EJERCICIO_BY_ID } from 'graphql/Queries/queries.alumno'
import { useState, useEffect } from 'react'

export const useDataExerciseById = (_id = '') => {
    const [dataExercise, setdataExercise] = useState({})

    const { data, loading: loadingExercise, error: errorExercise } = useQuery(GET_EJERCICIO_BY_ID, {
        variables: { _id },
        skip: _id === '' || !_id,
        fetchPolicy: 'no-cache'
    })

    useEffect(() => {
        if (data) setdataExercise(data.getEjercicioById)
        else setdataExercise({})
    }, [data])

    return [dataExercise, loadingExercise, errorExercise]
}


export const suffleArrayDaya = (array = []) => {
    let currentIndex = array.length, randomIndex

    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--
        [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]]
    }

    return array;
}