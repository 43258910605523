import React from 'react'
import { Item } from './Item'

export const ListEjercicios = ({ data }) => {

    return (
        <div className="component__listEjercicios">
            <div className="content">
                <ul className="scroll-desing">
                    {
                        data.map((e, i) => (
                            <Item data={e} key={i} />
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}
