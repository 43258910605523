import React from 'react'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import { GrupoItem } from './grupoItem'
import IMG from 'assets/svg/Error/box.svg'
import useWindowDimensions from 'components/Helpers/Windows'


const responsive = {
    0: { items: 1 },
    480: { items: 1},
    768: { items: 3},
    1366: {items: 4},
    1440: {items: 5},
    1920: {items: 6},
};

const refNoFoundData = React.createRef();

export const Grupos = ({grupos, libro}) => {
    const { width } = useWindowDimensions();

    const isInfinite = width < 480 ? true : false
    
    let items =  grupos.map((e, i) => (<GrupoItem grupo={e} key={i} libro={libro}/>))
    return (
        <div className="component_Grupos">
            {/* Carousel de grupos */}
            {/* {
                grupos.length >= 1 ? <AliceCarousel
                    mouseTracking
                    autoWidth={true}
                    disableButtonsControls
                    items={items}
                    responsive={responsive}
                    controlsStrategy="alternate"
                    disableDotsControls
                    className="carousel-grupos"
                    paddingLeft={10}
                    paddingRight={70}
                /> : <NoFoundData ref={refNoFoundData} />
            } */}
            {
                 grupos.map((e, i) => (<GrupoItem grupo={e} key={i} libro={libro}/>))
            }

        </div>
    )
}

const NoFoundData = React.forwardRef((props, ref) => (
    <div className="NoFoundData" ref={ref}>
        <div className="show_message">
            <div className="icon"><img src={IMG} alt="Error" /></div>
            <div className="text">Aún no tienes grupos, comienza creando el primero.</div>
        </div>
    </div>
));
