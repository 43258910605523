import React from 'react'
import CardRespuesta from './CardRespuesta'

const WrapperRespuestas = ({ data = [], onChangeColumn, identidadRef }) => {
    return (
        <div className="wrapper__column">
            <div className="title">
                <span>Respuestas</span>
            </div>
            <div className="cards anwers">
                {
                    data.map((respuesta, i) => {
                    return <CardRespuesta key={i} respuesta={respuesta} id={i} identidadRef={identidadRef} onChangeColumn={onChangeColumn}/>
                })}
            </div>
        </div>
    )
}

export default WrapperRespuestas
