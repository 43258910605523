import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { InformacionBasica } from './informacionBasica';
import { CambiarContrasena } from './cambiarContrasena';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { ImWhatsapp } from "react-icons/im";
import { helpAssistance } from './helpAssistance';

export const Configuracion = ({ close, dataUser }) => {
	return (
		<div className='custom__modal'>
			<div className='title'>
				<div className='text'>
					<h2>Configuración</h2>
				</div>
				<button className='close' onClick={close}>
					&times;
				</button>
			</div>
			<div className='content'>
				<Tabs className='tabs'>
					<TabList className='tabList'>
						<Tab className='tab'>Información basica</Tab>
						<Tab className='tab'>Contraseña</Tab>
					</TabList>

					<TabPanel className='tabPanel'>
						<div className='content'>
							<InformacionBasica data={dataUser} />
						</div>
					</TabPanel>
					<TabPanel>
						<div className='content'>
							<CambiarContrasena />
						</div>
					</TabPanel>
				</Tabs>
			</div>
		</div>
	);
};


export const Fag = ({ close }) => {
	return (
		<div className='custom__modal'>
			<div className='title'>
				<div className='text'>
					<h2>Ayuda y asistencia</h2>
				</div>
				<button className='close' onClick={close}>
					&times;
				</button>
			</div>
			<div className='content'>
            <Accordion allowZeroExpanded className='accordion__book'>

                {helpAssistance.map(help=>
					<AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>{help.title}</AccordionItemButton>
						</AccordionItemHeading>
                        {help.respuesta.map(content=> (
                            <AccordionItemPanel >
                                
                                <p>{content}</p>
                            </AccordionItemPanel>
                        ))}
					</AccordionItem>
                    )}
				</Accordion>
				
			</div>
        	<div className='fag__footer'>
        		<a target="_blank" href="https://api.whatsapp.com/send?phone=+52 1 221 667 1272">
					Envíanos un mensaje por WhatsApp. <ImWhatsapp/>
				</a>
			</div>
		</div>
	);
};
