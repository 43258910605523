import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export const AddBookRoute = ({ isAddingNewBook, isAuthorized, component: Component, ...rest }) => {

    return (
        <Route {...rest}
            component={
                (props) => (
                    (isAuthorized && isAddingNewBook)
                        ? <Component {...props} />
                        : <Redirect to="/" />
                )}
        />
    )
}
