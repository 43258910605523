import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import { Libro } from './Libro'

export const ListLibros = ({portafoliosEvidencia}) => {
    
    let items = portafoliosEvidencia.map(e => (<Libro data={
        { titulo   : e.titulo,
          grupo    : e.grupo.nombre,
          profesor : e.profesor,
          avance   : e.porcentajeAvance,
          estatus  : e.grupo.estatus,
          _id      : e._id }
    } />))
    

    return (
        <div className="wrapper__libros">
            <AliceCarousel
                mouseTracking
                items={items}
                innerWidth={400}
                autoWidth
                paddingRight={100}
                controlsStrategy="alternate"
                disableButtonsControls
            />
        </div>
    )
    
}
