import React from 'react'
import { ItemTema } from './itemTema'

export const ItemUnidad = ({ unidad, setIdTema }) => {
    
    const temas = unidad?.hijos ?? []
    
    return (
        <div className="component__itemUnidad">
            <details className="component__itemUnidad">
                <summary>
                    <span>{unidad.seccion} - {unidad.nombre}</span>
                    {/* <span>{unidad.nombre}</span> */}
                </summary>
                <div className="items">
                    {
                        temas.map((tema, index) => (
                            <ItemTema key={index} tema={tema} setIdTema={setIdTema}/>
                        ))
                    }
                </div>
            </details>
        </div>
    )
}
