import React from 'react'
import { Link } from 'react-router-dom'
import { useFormatExercise } from './hooks'

export const Item = ({ data }) => {

    let [format] = useFormatExercise(data.tipo.name)

    return (
        <li>
            <div className="icon" style={{ backgroundColor: format.color }} >{format.icon}</div>
            <div className="text">{format.text}</div>
            <Link to={`/profesor/ejercicio/${data._id}`} target="_blank" rel="noopener noreferrer">Ver ejercicio</Link>
        </li>
    )
}
