import { useState, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { GET_EJERCICIO_BY_ID } from "graphql/Queries/queries.alumno"

export const useEjercicio = (_id = '') => {
    const [dataEjercicio, setDataEjercicio] = useState({})
    
    const { data, loading: loadingEjercicio, error: errorEjercicio } = useQuery(GET_EJERCICIO_BY_ID, {
        variables: { _id },
        skip: _id === '',
        fetchPolicy: 'no-cache'
    })
    
    useEffect(() => {
        if (data) setDataEjercicio(data.getEjercicioById)
    }, [loadingEjercicio])

    return [dataEjercicio, loadingEjercicio, errorEjercicio]
}
