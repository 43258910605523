import React from 'react';
import ReactExport from 'react-export-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excel = ({ data, columns }) => {
	const alumnos = data.map(e => {
		return {
			matricula: e.alumno.matricula === null ? 'Sin matricula' : e.alumno.matricula,
			nombres: e.alumno.nombres,
			primerApellido: e.alumno.primerApellido,
			segundoApellido: e.alumno.segundoApellido,
			estatus: e.alumno.estatus === true ? 'Activo' : 'Baja',
			calificacion:
				e.calificacion === null ? 'Sin calificacion' : e.calificacion.toFixed(1),
		};
	});

	return (
		<ExcelFile
			filename='calificaciones'
			element={
				<button
					className='__btnCustom'
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: 5,
						backgroundColor: '#196F3D',
					}}
				>
					Exportar excel <RiFileExcel2Fill />
				</button>
			}
		>
			<ExcelSheet data={alumnos} name='Alumnos'>
				<ExcelColumn label='Matricula' value='matricula' />
				<ExcelColumn label='Nombres' value='nombres' />
				<ExcelColumn label='Apellido Paterno' value='primerApellido' />
				<ExcelColumn label='Apellido Materno' value='segundoApellido' />
				<ExcelColumn label='Estatus' value='estatus' />
				<ExcelColumn label='Calificación' value='calificacion' />
			</ExcelSheet>
		</ExcelFile>
	);
};

Excel.defaultProps = {
	data: [],
	columns: [],
};

export default Excel;
