import { useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import useSettings from 'hooks/useSettings'

const CardPregunta = ({pregunta, identidadRef}) => {
    const [getEnvDomainResources] = useSettings()
    const IMG = getEnvDomainResources()

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: ItemTypes.RESPUESTA,
        drop: () => ({ name: `${pregunta._id}` }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    })

    const isActive = canDrop && isOver;
    let bgColor = 'rgb(206, 212, 218,0.2)';
    if (isActive) {
        bgColor = '#CED4DA';
    }
    else if (canDrop) {
        bgColor = '#ADB5BD';
    }

    const textColor = identidadRef[pregunta._id]?.current?.style?.color !== '' ? `${identidadRef[pregunta._id].current?.style?.color}` : 'red'

    return (
        <div ref={drop} style={{ backgroundColor: `${bgColor}` }}>
            <div className="question card" ref={identidadRef[pregunta._id]}>
                { pregunta?.pregunta ?? '' }
                { pregunta?.imagen && <div className="image_wrapper"><img className="img_info" alt="" src={IMG + pregunta.imagen}></img></div>}
            </div>
        </div>
    )
}

export default CardPregunta
