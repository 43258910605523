import React, { useState, useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { Helmet } from 'react-helmet'

//Components
import Presentacion from './Presentacion'
import Grupos from './Grupos'
import { LoaderSection } from 'components/Helpers/Loader/Loader'
//Redux
import { useSelector } from 'react-redux'

//GraphQL
import { useQuery } from '@apollo/client'
import { GET_MY_GRUPOS_BY_ACADEMIA, GET_LIBRO_BY_ACADEMIA } from 'graphql/Queries/queries.profesor'

import './style.css'


export const Dashboard = () => {

    const {idAcademia} = useSelector(state => state.academia)
    const {userInfo} = useSelector(state => state.user)
    const [dataGrupos, setDataGrupos] = useState([])
    const [libro, setLibro] = useState(null)
    
    const loading = !userInfo || !idAcademia

    const { data: getGrupos, loading: loadingGrupos, error: errorGrupos } = useQuery(GET_MY_GRUPOS_BY_ACADEMIA,{ variables:{ _idAcademia : idAcademia}})

    const { data: libroDigital, loading: loadingLibro, error: errorLibro } = useQuery(GET_LIBRO_BY_ACADEMIA, {
        variables: {
            idAcademia: idAcademia
        }
    })

    useEffect(() => {
        if (getGrupos) setDataGrupos(getGrupos.getMyGruposByAcademia)
    }, [getGrupos])

    useEffect(() => {
        if(libroDigital) setLibro(libroDigital.getIndiceByTituloOrAcademia[0])
    } , [libroDigital])

    if (loading) return null
    
    const nombre = `${userInfo.nombres} ${userInfo.primerApellido}`
    
    return (
        <div className="component_Dashboard">
            <Toaster />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard - Ktdra Digital</title>
            </Helmet>
            <Presentacion data={nombre} /> 
            {loadingGrupos ? <LoaderSection/> :  <Grupos dataGrupo={dataGrupos} idAcademia={idAcademia} libro={libro}/>}
        </div>
    )
}
