import React from 'react'
import { useGlobalFilter, useTable, useFilters } from "react-table"
import Popup from 'reactjs-popup'
import { GlobalFilter } from './globalFilter'
import useWindowDimensions from 'components/Helpers/Windows'
import { GoSettings } from 'react-icons/go'
import { BiExport } from 'react-icons/bi'

export const Table = ({ columns, data, componets }) => {
console.log("🚀 ~ file: table.jsx ~ line 10 ~ Table ~ componets", componets)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,
        state, preGlobalFilteredRows, setGlobalFilter } =
        useTable({ columns, data, }, useFilters, useGlobalFilter);

    const { width } = useWindowDimensions();
    let isMobile = width < 768 ? true : false
    const position = ['bottom center', 'bottom left', 'center center']
    return (
        <div className="component__tableAlumnos">
            <div className="table__wrapper">
                <div className="search-options">
                   {/*  <button className="export-to"><BiExport /><span>Exportar a Excel</span></button> */}
                    <div className="dropdown">
                        { isMobile && <Popup
                            nested
                            className="float-dropdown"
                            trigger={<button className="open-mobile"><GoSettings /></button>}
                            position={position}
                            arrow={position !== 'center center'}
                            closeOnDocumentClick
                        >
                            <div className="mobile"><DropdownFilters headerGroups={headerGroups} /></div>
                        </Popup> }

                        <div className="desktop">
                            <DropdownFilters headerGroups={headerGroups} />
                        </div>

                    </div>
                    <div className="">{componets.map(e=>(e))}</div>
                    <div className="search">
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </div>
                </div>
                <div>
                    <table {...getTableProps()} className="table__alumnos">
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row);
                            
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td data-label={cell.column.Header} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const DropdownFilters = ({ headerGroups }) => {
    return (
        <>
            {headerGroups && headerGroups.map((headerGroup) =>
                headerGroup.headers.map((column) =>
                    column.Filter ? (
                        <div key={column.id} className="item">
                            <label htmlFor={column.id}>{column.render("Header")}: </label>
                            {column.render("Filter")}
                        </div>
                    ) : null
                )
            )}
        </>
    )
}
