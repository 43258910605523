import React from 'react'
import Portada from './Portada'
import { InfoPerfil } from './InfoPerfil'

import './styles.css'

export const Profile = () => {
    return (
        <div className="alumno__dashboard-portada">
            <Portada />
            <InfoPerfil/>
        </div>
    )
}
