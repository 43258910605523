import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

//Components
import { Header } from 'components/Profesor/Header'
import { Dashboard } from 'components/Profesor/Dashboard/Dashboard'
import Alumnos from 'components/Profesor/Alumnos'
import { Recursos } from 'components/Profesor/Recursos/Recursos'

import 'components/Profesor/profesor.css'
import { Testing } from 'components/Ejercicios/Testing'


export const ProfesorRoutes = () => {

    return (
        <div className="profesor__main">
            <Header />
            <div className="main__content">
                <Switch>
                    <Route exact path="/profesor/dashboard" component={Dashboard} />
                    <Route exact path="/profesor/alumnos" component={Alumnos} />
                    <Route exact path="/profesor/recursos" component={Recursos} />
                    <Route path="/profesor/ejercicio/:token" component={Testing} />
                    <Redirect to="/profesor/dashboard" /> 
                </Switch>
            </div>
        </div>
    )
}
