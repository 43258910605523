import { useEffect, useState } from 'react'
import { HangMan } from '../HangMan/HangMan'
import Memorama from '../Memorama'
import { Quiz } from '../Quiz'
import RelColumnas from '../RelCol'

export const useSwitchTypeExercise = (data = {}, finalized = false, setScoreFinal) => {
    const [switchExercise, setSwitchExercise] = useState(null)

    useEffect(() => {
        const { cuerpo, tipo } = data
        
        if (tipo) {
            switch (tipo.name) {
                case 'relCol':
                    setSwitchExercise(<RelColumnas content={cuerpo} finalized={finalized} onFinalize={setScoreFinal} />)
                    break;
                case 'memorama':
                    setSwitchExercise(<Memorama data={cuerpo} finalized={finalized} onFinalize={setScoreFinal} />)
                    break;
                case 'ahorcado':
                    setSwitchExercise(<HangMan data={cuerpo} finalized={finalized} onFinalize={setScoreFinal} />)
                    break;
                case 'quiz':
                    setSwitchExercise(<Quiz data={cuerpo} finalized={finalized} onFinalize={setScoreFinal} />)
                    break;

                default: break
            }
        }
    }, [data, finalized])


    return [switchExercise]
}