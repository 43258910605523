import { types } from '../../types/types';

export const actionLogin = (apiKey) => {
    return {
        type: types.login,
        payload:{
            apiKey: apiKey
        }
    }
}

export const actionLogout = () => {
    return {
        type: types.logout,
        payload:{
            apiKey: null,
        }
    }
}

export const setUserInfo = (userInfo) => {
    return {
        type: types.setUserInfo,
        payload: {
            userInfo: userInfo
        }
    }
}

export const updateUserInfo = (userInfo) => {
    return {
        type: types.updateUserInfo,
        payload: {
            userInfo: userInfo
        }
    }
}

export const setAcademia = (idAcademia, selectedBook) => {
    return {
        type: types.selectedAcademia,
        payload: {
            idAcademia: idAcademia,
            selectedBook: selectedBook
        }
    }
}

export const setGroup = (idGrupo) => {
    return {
        type: types.selectedGroup,
        payload: {
            idGroup: idGrupo
        }
    }
}

export const setPortEvids = (idPortEvids) => {
    return {
        type: types.selectedPortEvids,
        payload: {
            idPortEvids: idPortEvids
        }
    }
}

export const setTema = (idTema) => {
    return {
        type: types.selectedTema,
        payload: {
            idTema: idTema
        }
    }
}