import React, { useState } from 'react'
import { Field, Form, Formik, ErrorMessage } from 'formik'
import { FRM_REGISTER_ALUMNO_VALIDATION_SCHEMA } from 'utils/validationSchemas'
//Components
import { LoaderSection } from 'components/Helpers/Loader/Loader'
import ShowError from 'components/Helpers/Form/ShowError'
//GraphQL
import { useMutation } from '@apollo/client'
import { REGISTRO_ALUMNOS } from 'graphql/Mutations/mutation.registro'

import EXITO_SVG from 'assets/images/icons/exito.svg'
import { IoAlertCircleSharp } from 'react-icons/io5'

export const ModalCrearCuenta = ({ close }) => {

    const [errorRegister, setErrorRegister] = useState(null)
    const [successRegister, setSuccessRegister] = useState(null)

    const [registerAlumno, { loading: loadingRegisterAlumno }] = useMutation(REGISTRO_ALUMNOS, {
        onCompleted: () => {
            setSuccessRegister(true)
        },
        onError: (error) => {
            setErrorRegister(error.message)
        }
    })
    if (loadingRegisterAlumno) return <LoaderSection />
    if (successRegister) return <FormSucess />

    const handleCreateAccount = (values) => {
        setErrorRegister(null)
        registerAlumno({
            variables: {
                email: values.email.trim(),
                password: values.password.trim(),
                nombres: values.name,
                primerApellido: values.a_paterno,
                segundoApellido: values.a_materno,
                token: values.token.trim()
            }
        })
    }

    return (
        <div className="custom__modal">
            <div className="title">
                <div className="text">
                    <h2>Crear una cuenta</h2>
                    <p>Si eres alumno ingresa tus datos.</p>
                </div>
                <button className="close" onClick={close}>
                    &times;
                </button>
            </div>
            <div className="content">
                <Formik
                    initialValues={{
                        name: '',
                        a_paterno: '',
                        a_materno: '',
                        email: '',
                        password: '',
                        token: '',
                    }}
                    onSubmit={handleCreateAccount}
                    validationSchema={FRM_REGISTER_ALUMNO_VALIDATION_SCHEMA}
                    autocomplete="off"
                >
                    <Form className="Form">
                        <div className="input">
                            <label htmlFor="name">Nombre(s)</label>
                            <Field type="text" id="name" name="name" />
                            <ErrorMessage
                                name="name"
                                component="span"
                                className="msg-error"
                            />
                        </div>
                        <div className="input-group">
                            <div className="input">
                                <label htmlFor="a_paterno">Apellido paterno</label>
                                <Field type="text" id="a_paterno" name="a_paterno" />
                                <ErrorMessage
                                    name="a_paterno"
                                    component="span"
                                    className="msg-error"
                                />
                            </div>
                            <div className="input">
                                <label htmlFor="a_materno">Apellido materno</label>
                                <Field type="text" id="a_materno" name="a_materno" />
                                <ErrorMessage
                                    name="a_materno"
                                    component="span"
                                    className="msg-error"
                                />
                            </div>
                        </div>
                        <div className="input">
                            <label htmlFor="email">Correo electrónico</label>
                            <Field type="text" id="email" name="email" />
                            <ErrorMessage
                                name="email"
                                component="span"
                                className="msg-error"
                            />
                        </div>
                        <div className="input">
                            <label htmlFor="password">Contraseña</label>
                            <Field type="password" id="password" name="password" />
                            <ErrorMessage
                                name="password"
                                component="span"
                                className="msg-error"
                            />
                        </div>
                        <div className="input">
                            <label htmlFor="token">Token</label>
                            <Field type="text" id="token" name="token" />
                            <ErrorMessage
                                name="token"
                                component="span"
                                className="msg-error"
                            />
                        </div>
                        <div className="buttons-group">
                            <button className="btn primary" type="submit">Registrarme ahora</button>
                        </div>
                        {
                            errorRegister ? <ShowError message={errorRegister} /> : null
                        }
                    </Form>

                </Formik>
            </div>
        </div>
    )
}

const FormSucess = () => {
    return (
        <div className="custom__modal">
            <div className="content">
                <div className="success">
                    <div className="svg"><img src={EXITO_SVG} alt="Icono de exito" /></div>
                    <div className="text">
                        <h2>VERIFICA TU CORREO</h2>
                        <p>Te enviamos un mensaje para verificar que si eres dueño del correo.</p>
                    </div>
                </div>
            </div>
        </div>

    )
}
