import React from 'react'

import { useQuery } from '@apollo/client'
import { GET_PORTAFOLIO_EVIDENCIAS } from 'graphql/Queries/queries.alumno'
import { Libro } from './Libro'
import './styles.css'
import { LoaderSection } from 'components/Helpers/Loader/Loader'
import { useMyPortafolioEvidencia } from 'components/HooksCommon/portafolicoEvid.hook'

export const SelectLibro = ({close}) => {

    const [portafolioEvidencia, loadingPE, errorPE] = useMyPortafolioEvidencia()
    
    return (
        <div className="custom__modal selectLibro maxW768px">
            <div className="title">
                <div className="text">
                    <h2>Mis libros</h2>
                    <p>Selecciona el libro para acceder a los ejercicios.</p>
                </div>
                <button className="close" onClick={close}>
                    &times;
                </button>
            </div>
            <div className="content">
                <div className="wrapper__books scroll-desing">
                    {
                        loadingPE ? <LoaderSection /> :
                        portafolioEvidencia.map((e) => (
                            <Libro key={e._id} data={{
                                portada : e.titulo.portada,
                                titulo  : e.titulo.nombre,
                                autor   : e.titulo.autor,
                                avance  : e.porcentajeAvance,
                                estatus : e.grupo.estatus,
                                _id     : e._id
                            }} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
