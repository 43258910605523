import Button from 'components/common/Button';
import React from 'react';

export default function FinishWord({ isFinishWord = {}, onNextWord, isFinishGame = false }) {

    return (
        <div className="FinishWord">
            {(isFinishWord.estatus === true) ?
                <div className="finish__wrapper">
                    <Button onClick={() => onNextWord()} text={isFinishGame ? 'Enviar ejercicio' : 'Siguiente palabra'} />
                </div>
                : null
            }
        </div>
    );
}
