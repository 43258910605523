import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

//Reducers
import { authReducer } from "redux/reducers/authReducer"
import { academiaReducer } from "redux/reducers/academiaReducer"
import { userReducer } from "redux/reducers/userReducer"
import { groupReducer } from "./groupReducer"
import { portEvidsReducer } from "./portEvidsReducer"
import { ejerciciosReducer } from "./ejerciciosReducer"

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'academia', 'user', 'group', 'portEvids', 'ejercicios']
}

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    academia: academiaReducer,
    group: groupReducer,
    portEvids: portEvidsReducer,
    ejercicios: ejerciciosReducer
})

export default persistReducer(persistConfig, rootReducer)

 