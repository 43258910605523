import { useQuery } from "@apollo/client"
import {
    GET_CALIFICACIONES_BY_PORTAFOLIOE, GET_EJERCICIOS_ALUMNO_BY_TEMA,
    GET_PORTAFOLIO_EVIDENCIAS_BY_ID, GET_TEMAS_ON_PROCESS, GET_TEMAS_INACTIVOS
} from "graphql/Queries/queries.alumno"
import { useState, useEffect } from "react"


export const useListEjerciciosByTema = (_idTema = '') => {
    const [listEjercicios, setListEjercicios] = useState([])

    const { data, loadingListEjercicios, error: errorListEjercicios, refetch } = useQuery(GET_EJERCICIOS_ALUMNO_BY_TEMA, {
        variables: { _idTema },
        skip: _idTema === '' || !_idTema,
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    })

    useEffect(() => {
        if (data) setListEjercicios(data.getCalificacionesAlumnoByTema.filter(ejercicio => ejercicio.calificacion === null))
    }, [data])

    const handleResetListEjercicios = () => refetch()

    return [listEjercicios, loadingListEjercicios, errorListEjercicios, handleResetListEjercicios]
}


export const useDataLibroByIdPortEvd = (_idPortafolio = '') => {
    const [dataLibro, setDataLibro] = useState({})

    const { data, loading: loadingLibro, error: errorLibro, refetch } = useQuery(GET_CALIFICACIONES_BY_PORTAFOLIOE, {
        variables: { _idPortafolio },
        skip: _idPortafolio === '' || !_idPortafolio,
        fetchPolicy: "network-only",   // Used for first execution
        nextFetchPolicy: "cache-first", // Used for subsequent executions
        notifyOnNetworkStatusChange: true
    })

    useEffect(() => {
        if (data) setDataLibro(data.getCalificacionesByPortafolio[0])
    }, [data])


    const handleReset = () => refetch()

    return [dataLibro, loadingLibro, errorLibro, handleReset]
}

export const useTemasOnProcessByIdPortEvd = (_id = '') => {
    const [temasEnProceso, setTemasEnProceso] = useState([])

    const { data, loading: loadingEnProceso, error: errorEnProceso, refetch } = useQuery(GET_TEMAS_ON_PROCESS, {
        variables: { _id },
        fetchPolicy: "network-only",   // Used for first execution
        nextFetchPolicy: "cache-first", // Used for subsequent executions
        skip: _id = '' || !_id,
        notifyOnNetworkStatusChange: true
    })

    useEffect(() => {
        if (data) setTemasEnProceso(data.getPortafolioEvidenciaById?.grupo?.enProceso)
    }, [data])

    const handleResetTemasOnProcess = () => refetch()

    return [temasEnProceso, loadingEnProceso, errorEnProceso, handleResetTemasOnProcess]

}

export const useTemasInactivosByIdPortEvd = (_id = '') => {
    const [temasInactivos, setTemasInactivos] = useState([])

    const { data, loading: loadingInactivos, error: errorInactivos, refetch } = useQuery(GET_TEMAS_INACTIVOS, {
        variables: { _id },
        fetchPolicy: "network-only",   // Used for first execution
        nextFetchPolicy: "cache-first", // Used for subsequent executions
        skip: _id = '' || !_id,
        notifyOnNetworkStatusChange: true
    })
    useEffect(() => {
        if (!loadingInactivos && !errorInactivos) setTemasInactivos(data.getPortafolioEvidenciaById?.grupo?.temasInactivos)
    }, [loadingInactivos, errorInactivos, data])

    const handleResetTemasInactivo = () => refetch()

    return [temasInactivos, loadingInactivos, errorInactivos, handleResetTemasInactivo]
}

export const useDataPortafolioEvidenciaById = (_id = '') => {
    const [dataPortafolioEvid, setDataPortafolioEvid] = useState({})

    const { data, loading: loadingPortafolioEvid, error: errorPortafolioEvid } = useQuery(GET_PORTAFOLIO_EVIDENCIAS_BY_ID, {
        variables: { _id },
        skip: _id === '' || !_id,
        fetchPolicy: 'cache-first'
    })

    useEffect(() => {
        if (data) setDataPortafolioEvid(data.getPortafolioEvidenciaById)
    }, [data])

    return [dataPortafolioEvid, loadingPortafolioEvid, errorPortafolioEvid]
}

