import React, { useState } from 'react'

//Components
import Dialogs from 'components/Helpers/Dialogs'
import { Tooltip } from 'components/Helpers/Tooltip/Tooltip'
import { LoaderForm } from 'components/Helpers/Loader/Loader'
//GraphQL
import { useMutation } from '@apollo/client'
import { CHANGE_AVATAR } from 'graphql/Mutations/mutation.commons'

import AVATAR_DEFAULT from 'assets/images/icons/usuario-avatar.png'
import { MdDelete } from 'react-icons/md'
import useSettings from 'hooks/useSettings'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserInfo } from 'redux/actions/actions'


export const CambiarAvatar = ({ avatar }) => {

    const dispatch = useDispatch()
    const {userInfo} = useSelector(state => state.user)
    const [getEnvDomainResources] = useSettings()
    const URL_RESOURCES = getEnvDomainResources()
    const avatarProfile = avatar ? `${URL_RESOURCES}${avatar}` : AVATAR_DEFAULT

    const [updateMeAvatar, { loading }] = useMutation(CHANGE_AVATAR, {
        onCompleted: ({ updateMeAvatar }) => {
            if (updateMeAvatar) {
                Dialogs.Success('Foto de perfil', 'Se ha actualizado correctamente.')
                dispatch(updateUserInfo(userInfo))
            }
        },
        onError: (error) => {
            Dialogs.Error('Foto de perfil', `Oops! ocurrio un error al actualizar tu foto: ${error.message}`)
        }
    })

    const handleUploadAvatar = (e) => {
        updateMeAvatar({
            variables: {
                file: e.target.files[0]
            }
        })
    }

    if(loading) return <LoaderForm />

    return (
        <div className="change__avatar">
            <div className="image">
                <img src={avatarProfile} alt="profile" loading="lazy"/>
            </div>
            <div className="options">
                <div className="button-input-file">
                    <label htmlFor="file-upload" className="__btnCustom">
                        <i className="fa fa-cloud-upload"></i> Cambiar fotografia
                    </label>
                    <Tooltip trigger={<button className="__btnCustom delete" disabled><MdDelete /></button>}
                        content="Eliminar imagen de perfil"
                        onAction={'hover'} />
                    <input onChange={handleUploadAvatar} id="file-upload" type="file" accept="image/png, image/jpg, image/jpeg"  />
                </div>
                <div className="text">
                    <span>Los formatos aceptados son .jpg o .png</span>
                    <span>Tamaño maximo del archivo es 2Mb</span>
                </div>
            </div>
        </div>
    )
}

