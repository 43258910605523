import { ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client'

import storage from 'redux-persist/lib/storage'

const { REACT_APP_NODE_ENV, REACT_APP_API_DEV, REACT_APP_API_PROD_HTTPS, REACT_APP_API_PROD_HTTP } = process.env

const getOS = () => {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os
}

const setURI = (NODE_ENV) => {
    let uri = null
    switch (NODE_ENV) {
        case 'dev':
            uri = REACT_APP_API_DEV
            break;
        case 'prod':
            const deviceOS = getOS()
            if (deviceOS === 'iOS' || deviceOS === 'Mac OS') {
                if (window.location.protocol === "https:") {
                    window.location = document.URL.replace("https://", "http://")
                    console.log('redirect to http')
                }
                uri = REACT_APP_API_PROD_HTTP
                localStorage.setItem('device', 'Apple')
            } else {
                uri = REACT_APP_API_PROD_HTTPS
            }
            break;
        default:
            break
    }

    return uri
}


const httpLink = createUploadLink({ uri: setURI(REACT_APP_NODE_ENV) })


const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token')
    return {
        headers: {
            ...headers,
            authorization: token
        }
    }
})

const logoutLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            if (err.extensions.code === 'LOGIN_ERROR') {
                const urlFrom = window.location.pathname;
                const urlFormat = urlFrom.substring(0, urlFrom.lastIndexOf('/'));
                if (urlFormat !== '/registro/profesor') {
                    storage.removeItem('persist:root');
                    window.location.reload(false);
                }
            }
        }
    }
})

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: logoutLink.concat(authLink.concat(httpLink))
})
