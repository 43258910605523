import React from 'react'
import { ImHome, ImUsers } from 'react-icons/im'
import { IoFileTrayStackedSharp } from 'react-icons/io5'
import { NavLink } from 'react-router-dom'

export const Nav = () => {
    return (
        <div className="navegation">
            <div className="list-item">
                <NavLink to="/profesor/dashboard" activeClassName="selected">
                    <button className="item">
                        <ImHome />
                        <span>Dashboard</span>
                    </button>
                </NavLink>
                <NavLink to="/profesor/alumnos" activeClassName="selected">
                    <button className="item">
                        <ImUsers />
                        <span>Alumnos</span>
                    </button>
                </NavLink>
                <NavLink to="/profesor/recursos" activeClassName="selected">
                    <button className="item">
                        <IoFileTrayStackedSharp />
                        <span>Recursos</span>
                    </button>
                </NavLink>
            </div>
        </div>
    )
}
