import React from 'react'
//Components
import { LoaderSection } from 'components/Helpers/Loader/Loader'
//Redux
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setAcademia } from 'redux/actions/actions'
//GraphQL
import { useQuery } from '@apollo/client'
import { GET_MY_ACADEMIAS_BY_PROFESOR } from 'graphql/Queries/queries.profesor'

export const ModalSelectBook = ({close}) => {

    const {idAcademia} = useSelector(state => state.academia)
    const dispatch = useDispatch()
    const { data, loading, error } = useQuery(GET_MY_ACADEMIAS_BY_PROFESOR)
    const { REACT_APP_DOMAIN_RESOURCE } = process.env

    const handleBookSelect = (idSelectedAcademia, bookInfo ) => {
        dispatch(setAcademia(idSelectedAcademia, bookInfo))
        close()
    }

    return (
        <div className="component__modalSelectBook scroll-desing">
            <div className="title">
                <h2>Selecciona el libro</h2>
            </div>
            <div className="content">
                {
                    loading ? <LoaderSection /> :
                        <>
                            <div className="books-items">
                                {
                                    data?.getMyAcademiasByProfesor.map((e, i) => (
                                        <div 
                                            className={`book ${e._id === idAcademia ? `selected` : `noselect`}`} key={i}
                                            onClick={() => handleBookSelect(e._id, e.titulo)}
                                        >
                                            <img src={REACT_APP_DOMAIN_RESOURCE + e.titulo.portada} alt="" />
                                            <p>{e.titulo.nombre}</p>
                                            <div> <span>{e.plantel?.nombre ?? ''}</span><span>{e.plantel?.subsistema?.nombre ?? ''}</span> </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                }
                {
                    error && <span>{error.message}</span>
                }
            </div>
        </div>
    )
}
