import React from 'react'
import Popup from 'reactjs-popup'

import './tooltip.css'

/**
 * @function Tooltip
 * @desc - Muestra un tooltip
 * @param {Object} json - JavaScript object to strip
 * @param {Object[]} keys - array of selected keys (string)
 * @return {Object} - deep copy of object without keys
 */

export const Tooltip = ({ position, onAction, trigger, content }) => {
    const ref = React.createRef();
    return (
        <Popup
            trigger={trigger}
            on={onAction}
            className="tooltip"
        >
            <ContentTooltip ref={ref} content={content} />
        </Popup>
    )
}



const ContentTooltip = React.forwardRef(({ content }, ref) => (
    <div ref={ref} className="content_ToolTip">
        <p>{content}</p>
    </div>

));
