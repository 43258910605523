import React from 'react'
import useSettings from 'hooks/useSettings'

const MemoCard = ({data}) => {
    const [getEnvDomainResources] = useSettings()
    const IMG = getEnvDomainResources()
    const imgMostrar = data.imgCarta.url ? IMG+data.imgCarta.url : false
    
    return (
        <div className="inner">
            <div className="front">
                {imgMostrar && <div className="img"><img src={imgMostrar} alt="img"/></div> }
                <h1>{data.palabra}</h1>
            </div>
            
        </div>
    )
}

export default MemoCard