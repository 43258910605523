import React from 'react'

import useSettings from 'hooks/useSettings'
import { LatexDisplay } from 'components/common/LatexDisplay/LatexDisplay'
import { MathJaxContext } from 'better-react-mathjax';


const config = {
    "fast-preview": {
        disabled: true
    },
    tex2jax: {
        inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
        ],
        displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
        ]
    },
    messageStyle: "none"
};

export const AnswerBox = ({ number, answer, setSelected, itemsSelected }) => {

    const [getEnvDomainResources] = useSettings()

    const handleSelected = () => setSelected(answer._id)

    const classComponent = itemsSelected && itemsSelected.find(x => x === answer._id)
    const resourceImg = answer?.recursos[0]?.url ?? null

    return (
        <MathJaxContext
            version={2}
            config={config}
            onStartup={(mathJax) => (mathJax.Hub.processSectionDelay = 0)}
        >
            <div className={classComponent ? 'component__answerBox selected' : 'component__answerBox'} >
                <button className="btn__selected" onClick={handleSelected}>
                    <div className="number"><span>{number}</span></div>
                    <div className={resourceImg ? 'text with-img' : 'text'}>
                        <LatexDisplay
                            text={answer?.respuesta ?? ''}
                        />
                        {resourceImg && <div className="img"><img src={getEnvDomainResources() + resourceImg} alt="Recurso" /></div>}
                    </div>
                </button>
            </div>

        </MathJaxContext>
    )
}
