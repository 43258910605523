import React from 'react'

import logo from 'assets/images/kdtadigital-white.png'

export const PresentationLateral = ({ portada }) => {

    return (
        <div className="component__vPresentation">
            <div className="icon">
                <img src={logo} alt="Logotipo Ktdra Digital" />
            </div>
            <div className="portada-img">
                <div className="text">
                    <h2>¡ESTAS APUNTO DE REGISTRARTE AL TITULO DE!</h2>
                </div>
                <img src={portada} alt="portada del libro" />
            </div>
            <div className="background"></div>
        </div>
    )
}
