import React from 'react'

export const ItemTema = ({tema, setIdTema}) => {
    const {_id,nombre, seccion} = tema

    const handleChangeTema = (idTema) => {
        setIdTema(idTema)
    }
    return (
        <div className="component__itemTema">
            <label htmlFor="">
                <span style={{fontWeight: 'bold'}}>{seccion}</span>
                <span>{nombre}</span>
                <input type="radio" name="radio_tema" onChange={()=> {handleChangeTema(_id)}}/>
            </label>
        </div>
    )
}
