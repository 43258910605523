import { types } from "types/types"

const initialsTate = {
    idTema: ''
}

export const ejerciciosReducer = (state = initialsTate, action) => {
    switch(action.type) {
        case types.selectedTema:
            return {
                idTema: action.payload.idTema,
            }
        case types.logout:
            return {
                idTema: '',
            }
        default:
            return state
    }

}