import { types } from "types/types"

const initialState = {
    idAcademia: null,
    selectedBook: null,
}

export const academiaReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.selectedAcademia:
            return {
                idAcademia: action.payload.idAcademia,
                selectedBook: action.payload.selectedBook,
            }
        case types.logout:{
            return {
                idAcademia: null,
                selectedBook: null,
            }
        }
        default:
            return state
    }
}
