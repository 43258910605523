import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const DialogSuccess = (title, message) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        didOpen: () => {
            MySwal.clickConfirm()
        }
    }).then(() => {
        return MySwal.fire({
        position: 'center',
        icon: 'success',
        title,
        text: message,
        showConfirmButton: false,
        timer: 2200})
    })
}

const Dialogs = {
    Success: DialogSuccess,
    Error: DialogSuccess
}


export default Dialogs


