import React, { useState } from 'react'

import { NavLink } from 'react-router-dom'
import { HiHome, HiMenu } from 'react-icons/hi'
import { FaBookOpen, FaComment } from 'react-icons/fa'
import LOGO from 'assets/images/kdtadigital-white.png'
import { RiCloseFill } from 'react-icons/ri'
import Popup from 'reactjs-popup'
import { SelectLibro } from './SelectLibro'

export const Nav = () => {

    const [stateIsActiveMobile, setStateIsMobile] = useState(false)

    const handleIsShowMenu = () => setStateIsMobile(true)
    const handleIsHideMenu = () => setStateIsMobile(false)

    return (
        <div className="navegation-alumno">
            <button className="show menu-mobile" onClick={handleIsShowMenu}><HiMenu /></button>
            <div className={stateIsActiveMobile === true ? 'list-item' : 'list-item no-show'}>
                <div className="title-mobile">
                    <div className="img-logo"><img src={LOGO} alt="Logotipo Ktdra" /></div>
                    <button onClick={handleIsHideMenu}><RiCloseFill /></button>
                </div>
                <NavLink to="/alumno/dashboard" activeClassName="selected">
                    <button>
                        <HiHome />
                        <span>Inicio</span>
                    </button>
                </NavLink>
                {/* <NavLink to="/alumno/libros" activeClassName="selected"> </NavLink>  */}
                <Popup className="modal" modal closeOnDocumentClick={false} trigger={
                    <button>
                        <FaBookOpen />
                        <span>Libros</span>
                    </button>}>
                    { close => (<SelectLibro close={close} />) }
                </Popup>

                <NavLink to="/alumno/comunidad" activeClassName="selected">
                    <button>
                        <div className="badge">
                            Esperalo!
                        </div>
                        <FaComment />
                        <span>Comunidad</span>
                    </button>
                </NavLink>
            </div>
        </div>
    )
}
