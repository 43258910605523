import { MathJax, MathJaxContext } from "better-react-mathjax";

export const LatexDisplay = ({ text }) => {

    const config = {
        loader: { load: ["[tex]/html"] },
        tex: {
            packages: { "[+]": ["html"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
            ],
            displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
            ]
        }
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        }}>
            <MathJaxContext config={config}>
                <MathJax>{text}</MathJax>
            </MathJaxContext>
        </div>
    )
}