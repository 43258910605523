import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'

import { MdEmail } from "react-icons/md"
import EXITO_SVG from 'assets/images/icons/exito.svg'

import { useMutation } from '@apollo/client'
import { RECUPERAR_CONTRASENA } from 'graphql/Mutations/mutation.registro'
import ShowError from 'components/Helpers/Form/ShowError'
import { LoaderForm } from 'components/Helpers/Loader/Loader'

export const ModalRecuperarContrasena = ({ close }) => {

    const [isSuccess, setIsSuccess] = useState(false)
    
    const [recuperarCuenta, { loading }] = useMutation(RECUPERAR_CONTRASENA, {

        onCompleted: ({ forgotPasswordSendEmail }) => {
            if(forgotPasswordSendEmail._id) setIsSuccess(true)
        },
        onError: (error) => {
            setErrorMutation(error.message)
        }
    })

    const [errorMutation, setErrorMutation] = useState(null)

    const handleSubmit = (values) => {

        recuperarCuenta({
            variables: { email: values.email }
        })
    }
    if(isSuccess) return <ModalSuccess close={close} />
    
    return (
        <div className="custom__modal off-a">
            {loading ? <LoaderForm /> : null}
            <div className="title">
                <div className="text">
                    <h2>Recuperar tu cuenta</h2>
                    <p>Ingresa tu correo electrónico para buscar tu cuenta</p>
                </div>
                <button className="close" onClick={close}>
                    &times;
                </button>
            </div>
            <div className="content">
                <Formik
                    initialValues={{
                        email: '',
                    }}
                    onSubmit={handleSubmit}
                >
                    <Form className="Form">
                        <div className="input-icon">
                            <div>
                                <MdEmail />
                                <Field type="text" name="email" placeholder="Tu correo electronico" />
                            </div>
                        </div>
                        <div className="buttons-group right">
                            <button className="btn small primary" type="submit">Buscar</button>
                        </div>
                        {
                            errorMutation ? <ShowError message={errorMutation} /> : null
                        }
                    </Form>

                </Formik>
            </div>
        </div>
    )
}

const ModalSuccess = ({ close }) => {

    return (
        <div className="custom__modal off-a">
            <div className="success">
                <div className="svg"><img src={EXITO_SVG} alt="Icono de exito" /></div>
                <div className="text">
                    <h2>RECUPERAR TU CUENTA</h2>
                    <p>Te enviamos un mensaje a tu correo electronico para recuperar tu contraseña.</p>
                </div>
                <div className="buttons">
                    <button onClick={close}>Aceptar</button>
                </div>
            </div>
        </div>
    )
}
