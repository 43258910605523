import { gql } from '@apollo/client'

export const GET_MY_ACADEMIAS_BY_PROFESOR = gql`
    query getMyAcademiasByProfesor{
        getMyAcademiasByProfesor{
            _id,
            titulo{
                nombre,
                abrev,
                portada,
                descripcion,
                recursos{
                    nombre,
                    url,
                    esDescargable
                }
            },
            plantel {
                nombre,
                subsistema{
                    nombre
                }
            }
        }
    }
`

export const GET_MY_GRUPOS_BY_ACADEMIA = gql`
    query getMyGruposByAcademia($_idAcademia: ID){
        getMyGruposByAcademia(_idAcademia: $_idAcademia){
            _id
            nombre
            cupo
            cDisponible
            token
            estatus
            temasInactivos
            enProceso
        }
    }
`
export const GET_MY_RECURSOS_BY_ACADEMIA = gql`
    query getMyRecursosByAcademia(
        $idAcademia: ID
    ){
        getMyAcademiasByProfesor(
            _idAcademia: $idAcademia
        ){
            titulo{
                recursos{
                    nombre,
                    url,
                    esDescargable
                }
            }
        }
    }
`
export const GET_LIBRO_BY_ACADEMIA = gql`
    query getIndiceByTituloOrAcademia(
        $idAcademia: ID
    ){
        getIndiceByTituloOrAcademia(
            _idAcademia: $idAcademia
        ){
            _id,
            nombre,
            hijos{
                _id,
                nombre,
                seccion,
                hijos{
                    _id,
                    nombre,
                    seccion,
                    recursos{
                        url
                    }
                }
            }
        }
    }
`

export const GET_ALUMNOS_BY_ACADEMIA = gql`
    query getMyAlumnosByAcademiaAndParams(
        $_idAcademia: ID!
    ){
        getMyAlumnosByAcademiaAndParams(
            _idAcademia: $_idAcademia
        ){
            usuario{
                _id,
                matricula,
                nombres,
                primerApellido,
                segundoApellido,
                estatus,
                imgPerfil,
            }
            grupo{
                _id,
                nombre,
            }
        }
    }
`

export const GET_MY_TEMAS_BY_GRUPO = gql`
    query getMyTemasByGroup(
        $_idGrupo: ID!
    ){
        getMyTemasByGroup(
            _idGrupo: $_idGrupo
        ){
            _id,
            nombre,
            seccion
            hijos{
                _id,
                nombre,
                seccion
                hijos{
                    _id,
                    nombre,
                    seccion
                }
            }
        }
    }
`

export const GET_CALIFICACION_BY_GRUPO = gql`
    query getcalificacionbyGrupo(
        $_idGrupo: ID!,
        $_idIndice: ID!,
    ){
        getcalificacionbyGrupo(
            _idGrupo: $_idGrupo,
            _idIndice: $_idIndice,  
        ){
            alumno{
                _id,
                matricula,
                nombres,
                primerApellido,
                segundoApellido,
                estatus,
            }
            calificacion
        }
    }
`