
export const structureBookIndice = (book = {}, topicProcess = [], inactiveTopics = []) => {
    
    let bookStructured = []

    for (let i = 0; i < book.hijos.length; i++) {
        let tempUnidad = { num: i + 1, nombre: '', calificacion: 0, temas: [] }
        const unidad = book.hijos[i]

        tempUnidad.nombre = unidad?.nombre ?? 'N/E'
        tempUnidad.calificacion = unidad?.calificacion ?? 0

        for (let j = 0; j < unidad.hijos.length; j++) {
            let tempTema = { num: '0', nombre: '', calificacion: 0, disponible: false }
            const tema = unidad.hijos[j]

            const isInactive = inactiveTopics.find(e => e === tema._id)
            if (isInactive) break

            tempTema.num = `${i + 1}.${j + 1}`
            tempTema.nombre = tema?.nombre ?? 'N/E'
            tempTema.calificacion = tema?.calificacion ?? 0

            const isAvailable = topicProcess.find(e => e === tema._id)
            if (isAvailable) tempTema.disponible = true


            tempUnidad.temas.push(tempTema)
        }

        bookStructured.push(tempUnidad)
    }

    return bookStructured
}

export const useScoreTag = (score = 0) => {
    let scoreTag = 'default'
    if (score >= 9) scoreTag = '#008000'
    else if (score <= 8 && score >= 6) scoreTag = '#ffb600'
    else if (score >= 0) scoreTag = '#ad2831'

    return [scoreTag]
}