import { ButtonText } from 'components/common/Button'
import React, { useEffect, useState } from 'react'
import { structureBookIndice } from './hooks'
import ItemUnidad from './ItemUnidad'
import { ItemUnidadLoading } from './ItemUnidad'

import './styles.css'

const Indice = ({ libro = {}, temasEnProceso = false, temasInactivos = false, loading = false, onReset }) => {

    const [dataIndice, setDataIndice] = useState([])

    useEffect(() => {
        if (libro._id && temasEnProceso && temasInactivos) {
            const data = structureBookIndice(libro, temasEnProceso, temasInactivos)
            setDataIndice(data)
        }
    }, [libro, temasEnProceso, temasInactivos])

    return (
        <div className="component__indiceAlumno">
            <div className="title">
                <p>Temas disponibles</p>
                <ButtonText onClick={() => onReset()} text='Actualizar' loading={loading} />
            </div>
            <div className="content__indice">
                {
                    (loading && !dataIndice) ? <ItemUnidadLoading /> :
                        dataIndice.map((e, i) => (<ItemUnidad key={i} unidad={e} temasOnProcess={temasEnProceso} />))
                }
            </div>
        </div>
    )
}

export default Indice
