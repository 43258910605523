import React from 'react'
import toast from 'react-hot-toast'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { INFORMATION_ALUMNO_SCHEMA } from 'utils/validationSchemas'
//Redux
import { useDispatch, useSelector } from 'react-redux'
//GraphQL
import { useMutation } from '@apollo/client'
import { UPDATE_INFO } from 'graphql/Mutations/mutation.alumno'
import { updateUserInfo } from 'redux/actions/actions'

export const Configuracion = ({ close }) => {

    const {userInfo} = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [updateMeInfo] = useMutation(UPDATE_INFO, {
        onCompleted: ({updateMeInfo}) => {
            if(updateMeInfo._id) toast.success('Se ha actualizado tu información correctamente.')
            dispatch(updateUserInfo(updateMeInfo))
        }
    })

    const handleSubmit = (values) => {
        updateMeInfo({ variables: values })
    }

    return (
        <div className="component__configuracionAlumno">
            <div className="custom__modal w768">
                <div className="title">
                    <div className="text">
                        <h2>Configuración</h2>
                        <p>Configuración general de tu cuenta.</p>
                    </div>
                    <button className="close" onClick={close}>
                        &times;
                    </button>
                </div>
                <div className="content">
                    <Formik
                        initialValues={{
                            nombres: userInfo?.nombres ?? '',
                            primerApellido: userInfo?.primerApellido ?? '',
                            segundoApellido: userInfo?.segundoApellido ?? '',
                            email: userInfo?.email ?? '',
                            password: '',
                            matricula: userInfo?.matricula ?? '',
                            telefono: userInfo?.telefono ?? ''
                        }}
                        validationSchema={INFORMATION_ALUMNO_SCHEMA}
                        onSubmit={handleSubmit}
                    >
                        <Form className="Form">
                            <div className="input">
                                <label htmlFor="nombres">Nombre</label>
                                <Field type="text" name="nombres" id="nombres" />
                                <ErrorMessage name="nombres" component="span" className="msg-error" />
                            </div>
                            <div className="input-group">
                                <div className="input">
                                    <label htmlFor="primerApellido">Apellido paterno</label>
                                    <Field type="text" name="primerApellido" id="primerApellido" />
                                    <ErrorMessage name="primerApellido" component="span" className="msg-error" />
                                </div>
                                <div className="input">
                                    <label htmlFor="segundoApellido">Apellido materno</label>
                                    <Field type="text" name="segundoApellido" id="segundoApellido" />
                                    <ErrorMessage name="segundoApellido" component="span" className="msg-error" />
                                </div>
                            </div>
                            <div className="input-group">
                                <div className="input">
                                    <label htmlFor="email">Correo electronico</label>
                                    <Field type="text" name="email" disabled id="email" />
                                    <ErrorMessage name="email" component="span" className="msg-error" />
                                </div>
                                <div className="input">
                                    <label htmlFor="password">Contraseña</label>
                                    <Field type="password" name="password" id="password" />
                                    <ErrorMessage name="password" component="span" className="msg-error" />
                                    <span className="optional">Deja en blanco si no deseas cambiar tu contraseña.</span>
                                </div>
                            </div>
                            <div className="input-group">
                                <div className="input">
                                    <div className="input">
                                        <label htmlFor="telefono">Teléfono</label>
                                        <Field type="text" name="telefono" id="telefono" />
                                    </div>
                                </div>
                                <div className="input">
                                    <div className="input">
                                        <label htmlFor="matricula">Matricula</label>
                                        <Field type="text" name="matricula" id="matricula" />
                                    </div>
                                </div>
                            </div>
                            <div className="buttons-group">
                                <button className="btn primary" type="submit">Guardar cambios</button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}
