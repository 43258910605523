import React, { useEffect, useState } from 'react'
//Components
import { MiPerfil } from './Perfil'
import { Nav } from './nav'
import { SelectBook } from './seleckBook'
import { LoaderPage } from 'components/Helpers/Loader/Loader'
//Redux
import { useDispatch, useSelector } from 'react-redux'
import { setAcademia } from 'redux/actions/actions'
//GraphQL
import { useQuery } from '@apollo/client'
import { GET_MY_ACADEMIAS_BY_PROFESOR } from 'graphql/Queries/queries.profesor'
//Assets
import ICON_KTDRA from 'assets/images/kdtadigital-white.png'
import './header.css'


export const Header = () => {

    const dispatch = useDispatch()
    const [dataAcademia, setDataAcademia] = useState(null)
    const {userInfo} = useSelector(state => state.user)
    const {idAcademia, selectedBook} = useSelector(state => state.academia)

    const {data : getMyAcademiasByProfesor, loading} = useQuery(GET_MY_ACADEMIAS_BY_PROFESOR)
    
    useEffect(() => {
        if(getMyAcademiasByProfesor) setDataAcademia(getMyAcademiasByProfesor.getMyAcademiasByProfesor)
    }, [getMyAcademiasByProfesor])
        
    if(idAcademia === null || selectedBook === null) {
        if(dataAcademia) dispatch(setAcademia(dataAcademia?.[0]._id, dataAcademia?.[0].titulo))
    }
    
    if(loading) return <LoaderPage/>

    return (
        <div className="component__headerProfesor">
            <div className="book">
                <div className="icon">
                    <img src={ICON_KTDRA} alt="logo-kdtraDigital" />
                </div>
                <div className="selection-book">
                   <SelectBook/>
                </div>
            </div>
            <Nav />
            <div className="profile">
               <MiPerfil data={userInfo} /> 
            </div>
        </div>
    )
}
