import React from 'react'
import Popup from 'reactjs-popup'
import { ModalSelectBook } from './ModalSelectBook'
import { useSelector } from 'react-redux'

export const SelectBook = () => {

    const { REACT_APP_DOMAIN_RESOURCE } = process.env
    const {selectedBook} = useSelector(state => state.academia)
    if(!selectedBook) return null

    return (
        <div className="component__selectBook">
            <div className="book__information">
                <Popup className="modal__selectBook" trigger={<button className="selected__button">
                    <div className="img-book">
                        <img src={`${REACT_APP_DOMAIN_RESOURCE}${selectedBook.portada}`} alt="" />
                    </div>
                    <span className="title">{selectedBook.nombre}</span>
                    </button>} modal
                >
                    { close => (<ModalSelectBook close={close} />)}
                </Popup>
            </div>
        </div>
    )
}


