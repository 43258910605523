export const types = {
    login: '[auth] login',
    logout: '[auth] logout',
    setUserInfo: '[user] setUserInfo',
    updateUserInfo: '[user] updateUserInfo',
    selectedBook: '[books] selectedBook',
    selectedGroup: '[group] selectedGroup',
    selectedAcademia: '[academia] selectedAcademia',
    selectedPortEvids: '[portEvids] selectedPortEvids',
    selectedTema: '[ejercicios] selectedTema',
}