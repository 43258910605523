import animationDuck from 'assets/lotties/67908-duck.json'
import animationFourStars from 'assets/lotties/71706-get-four-stars.json'
import animationGoldScores from 'assets/lotties/69201-gold-scores-icon.json'

const useMostrarCalificacion = () => {

    const setLottie = (value) => { 
        let defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            },
            
        }

        if(value > 9) defaultOptions.animationData = animationGoldScores
        if(value < 9) defaultOptions.animationData = animationFourStars
        if(value < 7) defaultOptions.animationData = animationDuck

        return defaultOptions
    }

    return [setLottie]
}

export default useMostrarCalificacion