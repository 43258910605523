import React, { useState } from 'react'
import Popup from 'reactjs-popup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { FRM_LOGIN_VALIDATION_SCHEMA } from 'utils/validationSchemas'

//Components
import { ModalRecuperarContrasena } from './RecuperarCuenta'
import { ModalCrearCuenta } from './CrearCuenta'
import ShowError from 'components/Helpers/Form/ShowError'
import { Fag } from 'components/Profesor/Header/Configuracion'

//Redux
import { useDispatch } from 'react-redux'
import { actionLogin, setUserInfo } from 'redux/actions/actions'

//GraphQL
import { useMutation } from '@apollo/client'
import { LOGIN } from 'graphql/Mutations/mutation.commons'


export const FormLogin = () => {

    const [errorLogin, setErroLogin] = useState(null)
    const dispatch = useDispatch()

    const [login] = useMutation(LOGIN, {
        onCompleted: ({ login }) => {
            localStorage.setItem('token', login.token)
            dispatch(actionLogin(login.token))
            dispatch(setUserInfo(login.user))
        },
        onError: (error) => setErroLogin(error.message)
    })

    const handleLogin = (values) => {
        login({
            variables: {
                email: values.email.trim(),
                password: values.password.trim()
            }
        })

    }
    return (

        <div className="component_FormLogin">
            <div className="title">
                <h2>Iniciar sesión</h2>
                <p>Comparte tus ideas y comienza a conectar con tus compañeros.</p>
            </div>

            <div className="content">
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    validationSchema={FRM_LOGIN_VALIDATION_SCHEMA}
                    onSubmit={handleLogin}
                    autoComplete="off"
                >
                    <Form className="form-login">
                        <div className="input">
                            <Field type="text" name="email" placeholder="Tu correo electronico" />
                            <ErrorMessage
                                name="email"
                                component="span"
                                className="msg-error"
                            />
                        </div>
                        <div className="input">
                            <Field type="password" name="password" placeholder="Tu contraseña" />
                            <ErrorMessage
                                name="password"
                                component="span"
                                className="msg-error"
                            />
                        </div>
                        <button type="submit">Ingresar a mi cuenta</button>
                        {
                            errorLogin ? <ShowError message={errorLogin} /> : null
                        }
                        <div className="help">
                            <Popup className="modal" trigger={<button type="button">¿Has olvidado tu contraseña?</button>} modal position="right center">
                                {close => (<ModalRecuperarContrasena close={close} />)}
                            </Popup>

                            <Popup className="modal" trigger={<button type="button">Crear cuenta</button>} modal position="right center">
                                {close => (<ModalCrearCuenta close={close} />)}
                            </Popup>
                            
                            <Popup className="modal" trigger={<button type="button">Ayuda</button>} modal position="right center">
                                {close => (<Fag close={close}/>)}
                            </Popup>
                        </div>
                    </Form>
                </Formik>

            </div>
        </div>

    )
}


