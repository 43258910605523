import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import Tablero from './Tablero'
import { BiCheck } from 'react-icons/bi'
import './memorama.css'
import Button from 'components/common/Button'

const Memorama = ({ data = {}, onFinalize, finalized = false }) => {

    
    const [correctos, setCorrectos] = useState(0)

    const handleEnviar = () => {
        const calificacion = correctos / data.length * 10
        onFinalize(calificacion)
    }

    useEffect(() => {
        if (finalized) {
            const calificacion = correctos / data.length * 10
            onFinalize(calificacion)
        }
    }, [finalized])

    return (
        <>
            <div className="wrapper_memorama">
                <div className="title">Encuentra los pares en el siguiente memorama.</div>
                <div className="wrapper_tablero">
                    <Tablero 
                        data={data} 
                        correctos={correctos} 
                        setCorrectos={setCorrectos}  /> 
                </div>

                <div className="buttons_exercises ">
                    <Button text='Enviar resultados' type='success' onClick={handleEnviar} /> 
                </div>
            </div>
        </>
    )
}

export default Memorama
