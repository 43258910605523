import { gql } from '@apollo/client'


export const REGISTER_TOKEN = gql`
    mutation registerToken ($token: String!) {
        registerToken (token: $token)
    }
`
export const UPDATE_INFO = gql`
    mutation updateMeInfo (
        $telefono: String,
        $matricula: String,
        $nombres: String,
        $primerApellido: String,
        $segundoApellido: String
        $password: String
    ) {
        updateMeInfo(data: {
            telefono: $telefono,
            matricula: $matricula,
            nombres: $nombres,
            primerApellido: $primerApellido,
            segundoApellido: $segundoApellido,
            password: $password
        }) {
            _id,
            email,
            nombres,
            primerApellido,
            segundoApellido,
            imgPerfil,
            telefono,
            matricula,
            rol{
                _id,
                name
            } 
        }
    }
`

export const REGISTER_CALIFICACION = gql`
    mutation registerCalificacion (
        $_idEjercicio: ID!,
        $_idPortafolio: ID!,
        $calificacion: Float!,
    ) {
        registerCalificacion(data: {
            _idEjercicio: $_idEjercicio,
            _idPortafolio: $_idPortafolio,
            calificacion: $calificacion
        })
    }
`