import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
//Components
import { Dashboard } from 'components/Alumno/Dashboard/Dashboard'
import { Header } from 'components/Alumno/Header/Header'
import { Libros } from 'components/Alumno/Libros/Libros'
import { Comunidad } from 'components/Alumno/Comunidad/Comunidad'
import { Ejercicios } from 'components/Ejercicios/Ejercicios'

export const AlumnoRoutes = () => {
    return (
        <div className="main__alumno">
            <Header />
            <div className="content__main">
                <Switch>
                    <Route exact path="/alumno/dashboard" component={Dashboard}/>
                    <Route exact path="/alumno/ejercicios" component={Ejercicios}/> 
                    <Route exact path="/alumno/libro" component={Libros}/>

                    <Route exact path="/alumno/libro/:idPortafolio" component={Libros}/>
                    <Redirect to="/alumno/dashboard"/>
                </Switch>
            </div>
        </div>
    )
}
