import React from 'react'

//Components
import { Libros } from './Libros'
import { Profile } from './Profile'
import { Publicaciones } from './Publicaciones'
import { Toaster } from 'react-hot-toast'

import './styles.css'

export const Dashboard = () => {

    return (
        <div className="alumno__dashboard space">
            <Toaster />
            <div className="wrapper__profile">
                <Profile/>
            </div>
            <div className="wrapper__pubs">
                <Publicaciones />
            </div>
            <div className="wrapper__books">
                <Libros  />
            </div>
        </div>
        
    )
}
