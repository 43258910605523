import React, { useEffect, useState } from 'react'

import { ItemUnidad } from './itemUnidad'
import { LoaderSection } from 'components/Helpers/Loader/Loader'

import { useSelector } from 'react-redux'

import { useQuery } from '@apollo/client'
import { GET_MY_TEMAS_BY_GRUPO } from 'graphql/Queries/queries.profesor'

import { GoAlert } from 'react-icons/go'
import './styles.css'

export const Indice = ({ setIdTema }) => {

    const [indice, setIndice] = useState([])
    const {idGroup} = useSelector(state => state.group)

    const { data: getIndice, loading, error } = useQuery(GET_MY_TEMAS_BY_GRUPO, {
        variables: { _idGrupo: idGroup },
    })

    useEffect(() => {
        if (getIndice) setIndice(getIndice.getMyTemasByGroup[0])
    }, [getIndice])

    if(loading) return <LoaderSection />

    return (
        <div className="indice__titulo">
            <div className="title">
                <p>Unidades y temas de libro</p>
            </div>
            <div className="options">
                {
                    //Fix on future not use params and use redux instead
                    (idGroup === '' || indice.length === 0) ? <span className="none-data"><GoAlert />Selecciona un grupo para visualizar el indice.</span> :
                    indice.hijos.map((unidad, index) => (<ItemUnidad key={index} unidad={unidad} setIdTema={setIdTema}/>))
                }
            </div>
        </div>
    )
}
