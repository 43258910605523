import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { useMutation } from '@apollo/client'
import { CHANGE_ESTATUS_GRUPO, DELETE_GRUPO } from 'graphql/Mutations/mutation.profesor'
import { GET_MY_GRUPOS_BY_ACADEMIA } from 'graphql/Queries/queries.profesor'

import { BsBookmarkCheck, BsBookmarkDash, BsEye } from 'react-icons/bs'
import { HiDotsHorizontal, HiUserGroup } from 'react-icons/hi'
import { IoCopyOutline } from 'react-icons/io5'
import { RiDeleteBin7Line } from 'react-icons/ri'
import { BsEyeSlash } from 'react-icons/bs'

import Popup from 'reactjs-popup'
import toast from 'react-hot-toast'

import CopyToClipboard from 'react-copy-to-clipboard'
import { TemaEnProceso } from './TemaEnProceso'
import { IndiceLibro } from './IndiceLibro'

export const GrupoItem = ({ grupo, libro }) => {

    const { _id, estatus, token, temasInactivos, enProceso } = grupo
    
    const cDisponible = grupo.cupo - grupo.cDisponible
    const { idAcademia } = useSelector(state => state.academia)
    if (!idAcademia) return null

    return (
        <div className="component__grupoItem">
            <div className="header">
                <div className={'icon ' + estatus}><HiUserGroup /></div>
                <div className="options">
                    <Popup
                        nested
                        className="option-modal"
                        trigger={<button><HiDotsHorizontal /></button>}
                        position={['bottom right', 'bottom left']}
                        closeOnDocumentClick
                    >
                        {close => (<Options
                            _id={_id}
                            token={token}
                            estatus={estatus}
                            close={close}
                            idAcademia={idAcademia}
                            libro={libro}
                            temasInactivos={temasInactivos}
                            enProceso={enProceso}
                        /> )}
                    </Popup>
                </div>
            </div>
            <div className="info">
                <div className="name">
                    <span>Grupo {grupo?.nombre}</span>
                </div>
                <div className="size_group">
                    <p>{cDisponible}/{grupo?.cupo} alumnos</p>
                </div>
            </div>

        </div>
    )
}

const Options = ({ _id, token, estatus, close, idAcademia, libro, temasInactivos, enProceso }) => {

    const [changeStatus, setChangeStatus] = useState(!estatus)

    const [deleteGrupo, { loading: loadingDelete }] = useMutation(DELETE_GRUPO, {
        onCompleted: ({ deleteGrupo }) => {
            if (deleteGrupo) {
                toast.dismiss()
                toast.success('Grupo eliminado correctamente')
            }
        },
        onError: (error) => {
            toast.dismiss()
            toast.error('Ocurrió un error: ' + error.message)
        },
        refetchQueries: [{
            query: GET_MY_GRUPOS_BY_ACADEMIA,
            variables: {
                _idAcademia: idAcademia,
            }
        }]
    })

    const [disableGrupo, { loading: loadingEstatus }] = useMutation(CHANGE_ESTATUS_GRUPO, {
        onCompleted: ({ disableGrupo }) => {
            if (disableGrupo) {
                toast.dismiss()
                const statusText = estatus === false ? 'activado' : 'desactivado'
                toast.success(`El grupo se ha ${statusText} correctamente.`)
                close()
            }
        },
        onError: (error) => {
            toast.dismiss()
            toast.error('Ocurrió un error: ' + error.message)
        },
        refetchQueries: [{
            query: GET_MY_GRUPOS_BY_ACADEMIA,
            variables: {
                _idAcademia: idAcademia,
            }
        }]
    })

    if (loadingDelete || loadingEstatus) toast.loading('Cargando...')

    const handleCopyToken = () => {
        toast.success(`Token  ${token} copiado al portapapeles`)
        close()
    }

    const handleDelete = () => deleteGrupo({ variables: { _idGrupo: _id } })

    const handleEstatus = () => disableGrupo({ variables: { _idGrupo: _id, estatus: changeStatus } })

    const dataTemaProceso = { idGrupo: _id, temasInactivos: temasInactivos, libro: libro, inProcess: enProceso,  }

    return (
        <div className="component_options">
            <div className="list">
                <div className="item">
                    <Popup
                        nested
                        className="modal"
                        trigger={<button>
                            <BsBookmarkCheck />
                            <div className="text">
                                <span>Tema en proceso</span>
                                <div>Asigna un tema para que los alumnos contesten.</div>
                            </div>
                        </button>
                        }
                        modal
                        position={['bottom right', 'bottom left']}
                        closeOnDocumentClick >
                        { close => (<TemaEnProceso data={dataTemaProceso} close={close}  /> ) }
                    </Popup>
                </div>
                <div className="item">
                    <CopyToClipboard text={token}>
                        <button onClick={handleCopyToken}>
                            <IoCopyOutline />
                            <div className="text">
                                <span>Copiar token</span>
                                <div>Comparte el token con tus alumnos.</div>
                            </div>
                        </button>
                    </CopyToClipboard>
                </div>
                <div className="item">
                    <button onClick={handleEstatus}>
                        {estatus === true ? <BsEyeSlash /> : <BsEye />}
                        <div className="text">
                            {estatus === true ? <span>Desactivar grupo</span> : <span>Activar grupo</span>}
                            {estatus === true ? <div>Los alumnos no podran acceder al grupo.</div> : <div>Los alumnos podran acceder al grupo.</div>}
                        </div>
                    </button>
                </div>
                <div className="item">
                    <button onClick={handleDelete}>
                        <RiDeleteBin7Line />
                        <div className="text">
                            <span>Eliminar grupo</span>
                            <div>No podras recuperar el grupo.</div>
                        </div>
                    </button>
                </div>
                <div className="item">
                    <Popup
                        nested
                        className="modal"
                        trigger={<button>
                            <BsBookmarkDash />
                            <div className="text">
                                <span>Desactivar temas</span>
                                <div>Desactiva temas para este grupo.</div>
                            </div>
                        </button>
                        }
                        modal
                        position={['bottom right', 'bottom left']}
                        closeOnDocumentClick
                    >
                        {close => (<IndiceLibro
                            libro={libro}
                            idGrupo={_id}
                            temasInactivos={temasInactivos}
                            idAcademia={idAcademia}
                            close={close}
                        />)}
                    </Popup>
                </div>

            </div>
        </div>
    )
}
