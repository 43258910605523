import React from 'react'

export const Presentacion = ({data}) => {
    return (
        <div className="component__Presentation">
            <div className="name">
                Buen día, <h2>{data}!</h2>            
            </div>
        </div>
    )
}
