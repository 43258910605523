import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({isAuthorized, component:Component,...rest}) => {

    return (
        <Route {...rest}
            component={
                (props)=>(
                    (isAuthorized)
                    ? <Component {...props}/>
                    : <Redirect to="/home" />
                ) }
        />
    )
}
