import { types } from "types/types"

const initialState = {
    idGroup: null,
}

export const groupReducer = (state = initialState, action) => {
    switch (action.type){
        case types.selectedGroup:{
            return {
                idGroup: action.payload.idGroup
            }
        }
        case types.logout:{
            return {
                idGroup: null
            }
        }
        default: 
            return state
    }
}