import { LoaderModal } from 'components/Helpers/Loader/Loader';
import React, { useState, useEffect } from 'react'
import { HangMan } from '../HangMan/HangMan';
import Memorama from '../Memorama';
import { Quiz } from '../Quiz';
import RelColumnas from '../RelCol';

export const ContentEjercicio = ({ type, data = [] }) => {
    const [dataScore, setDataScore] = useState(null)
    const [calificacionFinal, setCalificacionFinal] = useState(null)
    
    const handleSendData = (data) => setCalificacionFinal(data)
    
    return (
        <div className="component__contentEjercicio">
            <div className="content-exercise">
                { 
                    (!calificacionFinal && calificacionFinal !== 0 ) ? (data.length > 0) ? 
                    <TypeEjercicio type={type} data={data} onSendData={handleSendData} dataScore={setDataScore} /> : 'No hay información del ejercicio.'
                    : <ShowCalificacion calificacionFinal={calificacionFinal} handleCalificacion={setCalificacionFinal} />
                }
            </div>
        </div>
    )
}

const ShowCalificacion = ({ calificacionFinal, handleCalificacion }) => {

    const handleReset = () => handleCalificacion(null)

    return (
        <div className="c__showCalificacion">
            <h2>{`Calificación final : ${calificacionFinal}`}</h2>
            <div className="button">
                <button type='button' className='__btnCustom' onClick={handleReset}>Reiniciar</button>
            </div>
        </div>
    )
}


const TypeEjercicio = ({type, data, onSendData, dataScore}) => {
    return (
        <>
        {
            type === 'relCol' ?
                    <RelColumnas content={data} onFinalize={onSendData}  /> :
                    type === 'quiz' ?
                        <Quiz data={data} onFinalize={onSendData}/> :
                        type === 'memorama' ?
                            <Memorama data={data} onFinalize={onSendData} /> :
                            type === 'ahorcado' ?
                                <HangMan data={data} onFinalize={onSendData}  /> : null
        }
        </>
    )
}
