import React from 'react'
import { useContentText } from './hooks'
import Popup from 'reactjs-popup'
import { MostrarRecurso } from './MostrarRecurso'

import './styles.css'
import Button from 'components/common/Button'
import NoSelectedExercise from './NoSelectedExercise'
import { LoaderSection } from 'components/Helpers/Loader/Loader'

export default function BeforeMessage({ onStart, onCancel, selectedExercise = false, loading = false, stateExercise = {}, lengthExercises = 0 }) {

    const { typeResource, url, latex, started, isResource, isTimer, finalized } = stateExercise
    const data = useContentText(isTimer, isResource)
    let textNoSelectedExercise = lengthExercises > 0 ? '¡Comienza seleccionando un ejercicio!' : '¡No hay ejercicios por el momento!'

    //const ref = React.createRef()

    if (loading) return <LoaderSection />

    if (!selectedExercise && !started && !loading && !finalized) return <NoSelectedExercise text={textNoSelectedExercise} />

    else if (selectedExercise && !started && !loading && !finalized)
        return (
            <div className="component__mostrarMensajePre">
                <div className="title">
                    <div className="icon">{data.state.icon}</div>
                    <h2>{data.state.title}</h2>
                </div>
                <div className="content">
                    <p>{data.state.message}</p>
                </div>
                <div className="buttons_exercises ">
                    {
                        isResource ? <>
                            <Popup className="modal" trigger={<button className='component__button primary'>Ver recurso</button>} modal position="right center">
                                {close => (<MostrarRecurso close={close} type={typeResource} url={url} latex={latex} />)}
                            </Popup>
                            <Button text='Comenzar' onClick={() => onStart()} color='primary' />
                            <Button text='Cancelar' onClick={() => onCancel()} color='cancel' />
                        </> : isTimer ?
                            <>
                                <Button text='Comenzar' onClick={() => onStart()} />
                                <Button text='Cancelar' onClick={() => onCancel()} color='cancel' />
                            </> :
                            <>
                                <Button text='Comenzar' onClick={() => onStart()} />
                                <Button text='Cancelar' onClick={() => onCancel()} color='cancel' />
                            </>
                    }
                </div>
            </div>
        )
    else return null

}


