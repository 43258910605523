import React, { useState } from 'react';

import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './viewpdf.css';
// Worker
import { Worker } from '@react-pdf-viewer/core';
import { IoArrowBackOutline } from 'react-icons/io5';

export const ViewerPDF = ({ urlRecurso, close }) => {
	const [defaultPdfFile, setdefaultPdfFile] = useState('a');
	const defaultLayoutPluginI = defaultLayoutPlugin({
		toolbarPlugin: {
			getFilenameFromUrl: false,
			getFilePlugin: false,
			selectionModePlugin: false,
			printPlugin: false,
		},
	});

	document.addEventListener('keydown', e => {
		e.preventDefault();
	});
	return (
		<div className='component__viewerPDF'>
			<div className='header'>
				<button onClick={close}>
					<IoArrowBackOutline />
				</button>
				<span>Visor de PDF</span>
			</div>
			<div className='content'>
				{defaultPdfFile && (
					<>
						<Worker
							className='viewer'
							workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'
						>
							<Viewer
								className=''
								fileUrl={`${process.env.REACT_APP_DOMAIN_RESOURCE}${urlRecurso}`}
								plugins={[]}
								defaultScale={2}
							/>
						</Worker>
					</>
				)}
			</div>
		</div>
	);
};
