export const helpAssistance = [
	{
		title: 'Problemas con el inicio de sesión',
		respuesta: [
			'- Verifica si el correo electrónico o contraseña es correcta',
			'- Si olvidaste tu contraseña, puedes ir al apartado de "¿Has olvidado tu contraseña?" para poder hacer el cambio',
			'- Verifica si tu cuenta ya ha sido verificada',
		],
	},
	{
		title: 'Verificación de la cuenta',
		respuesta: [
			'- Para la verificación de la cuenta, te llegara un email en tu correo electrónico que hayas seleccionado',
			'- Debes entrar el correo que te haya recibido con el título "Verificación de cuenta"',
			'- Deberá darle click al botón de "Verificar cuenta", este te va a redirigir a la página principal del KTDra digital',
			'- Ahora, puedes iniciar sesión de manera correcta con tu correo verificado',
		],
	},
	{
		title: 'Contenido no disponible',
		respuesta: [
			'- Comunicate con nosotros por medio de WhatsApp para preguntar por el contenido',
		],
	},
];
