import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client'
import { REGISTER_CALIFICACION } from 'graphql/Mutations/mutation.alumno'
import { GET_EJERCICIOS_ALUMNO_BY_TEMA } from 'graphql/Queries/queries.alumno';
import { useSelector } from 'react-redux'
import { useState } from 'react';

export const useSendScore = () => {
    const {idTema} = useSelector(state => state.ejercicios)
    const [stateError, setStateError] = useState(null)

    const [calificar, { loading: loadingSendCalif }] = useMutation(REGISTER_CALIFICACION,{
        refetchQueries: [{
            query: GET_EJERCICIOS_ALUMNO_BY_TEMA,
            variables: { _idTema: idTema },
        },
    ],
        onError: (errors) => {
            setStateError(errors.message)
        }
    })

    const sendCalificar = (idPortafolio, idEjercicio, scoreFinal) => {
        if (idEjercicio && idPortafolio){
            calificar({
                variables: {
                    _idEjercicio: idEjercicio,
                    _idPortafolio: idPortafolio,
                    calificacion: scoreFinal
                }
            }) 
        } else setStateError('Hay campos incompletos.')
    }

    return [sendCalificar, stateError, loadingSendCalif]
}
