import React, { useRef, createRef, useState, useEffect } from 'react'
import { BiCheck } from 'react-icons/bi'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import WrapperPreguntas from './WrapperPreguntas'
import WrapperRespuestas from './WrapperRespuestas'
import Swal from 'sweetalert2'
import useRelCol from './hooks'

import './relcolumnas.css'
import Button from 'components/common/Button'
import TitleExercise from '../TitleExercise'

const RelColumnas = ({ content, onFinalize, finalized = false }) => {

    const [getScoreFinal] = useRelCol()

    let columnaPreguntas = []
    let columnaRespuestas = []

    const itemRef = useRef({})
    itemRef.current = { preg: {}, res: {} }

    content.forEach((pair, index) => {
        const preguntaF = pair.columna1 === "" ? false : pair.columna1
        const respuestaF = pair.columna2 === "" ? false : pair.columna2
        const imgPregunta = pair.recursos.length === 2 ? pair.recursos[0].url : pair.columna1 === '' ? pair.recursos[0].url : false
        const imgRespuesta = pair.recursos.length === 2 ? pair.recursos[1].url : pair.columna2 === '' ? pair.recursos[0].url : false
        const _idItem = pair._id

        columnaPreguntas.push({
            pregunta: preguntaF,
            imagen: imgPregunta,
            index,
            _id: _idItem
        })
        columnaRespuestas.push({
            respuesta: respuestaF,
            imagen: imgRespuesta,
            index,
            _id: _idItem
        })

        itemRef.current.preg[_idItem] = createRef()
        itemRef.current.res[_idItem] = createRef()
    })


    columnaPreguntas = columnaPreguntas.sort(() => Math.random() - 0.5)
    columnaRespuestas = columnaRespuestas.sort(() => Math.random() - 0.5)

    const variableRef = itemRef.current.preg
    const totalEjercicios = Object.keys(variableRef).length

    const detectMob = () => {
        let response = false
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
        ]
        toMatch.some(() => {
            response = true
            return ''
        })
        return response
    }

    let backend = detectMob === true ? TouchBackend : HTML5Backend



    const calificarEnviar = () => {

        Swal.fire({
            title: '¿Estas seguro de enviar este ejercicio',
            text: "¡Si tu calificacion es enviada no se podra corregir!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, Cancelar',
            confirmButtonText: 'Si, enviar'
        }).then((result) => {
            if (result.isConfirmed) {
                const score = getScoreFinal(variableRef, totalEjercicios)
                onFinalize(score === 0 ? '0.00' : score)
                
            }
        })

    }

/*     const onChangeColumn = () => {
        const califFinalEnviar = getScoreFinal(variableRef, totalEjercicios)
    } */

    return (
        <div className="component__relColumnas">
            <TitleExercise title='Cuestionario' description='Contesta correctamente las preguntas que se te presenta.' />
            <div className="columns noselect">
                <DndProvider backend={backend}>
                    <WrapperPreguntas data={columnaPreguntas} identidadRef={itemRef.current.preg} />
                    <WrapperRespuestas data={columnaRespuestas} identidadRef={itemRef.current} />
                </DndProvider>
            </div>
            <div className="buttons_exercises">
                <Button text='Enviar respuestas' onClick={calificarEnviar} />
            </div>
        </div>
    )
}

export default RelColumnas