import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useHistory } from 'react-router-dom'
import { LOGIN_VALIDATION_SCHEMA } from 'utils/validationSchemas'

//Components
import ShowError from 'components/Helpers/Form/ShowError'

//GraphQL
import { useMutation } from '@apollo/client'
import { LOGIN } from 'graphql/Mutations/mutation.commons'

//Redux
import { useDispatch } from 'react-redux'
import { actionLogin, setUserInfo } from 'redux/actions/actions'

// Icons
import { MdEmail } from "react-icons/md"
import { RiLockPasswordFill } from "react-icons/ri"

export const ModalLogin = ({ estadosPlanteles, idLibro, close }) => {

    const [estados, setEstados] = useState([])
    const [planteles, setPlanteles] = useState([])
    const [estadoSelected, setEstadoSelected] = useState('')
    const [errorLogin, setErrorLogin] = useState('')

    const history = useHistory()
    const dispatch = useDispatch()

    const handleSelectEstado = (e) => setEstadoSelected(e.target.value)

    useEffect(() => {
        if (estadosPlanteles) {
            estadosPlanteles.map(estado => (setEstados(estados => [...estados, estado.estado[0]])))
            setEstadoSelected(estadosPlanteles[0]?.estado[0]?._id)
        }
    }, [estadosPlanteles])

    useEffect(() => {
        if (estadosPlanteles) {
            setPlanteles(estadosPlanteles.filter(plantel => plantel.estado[0]._id === estadoSelected))
        }
    }, [estadosPlanteles, estadoSelected])

    const [login, { loading }] = useMutation(LOGIN, {
        onCompleted: ({ login }) => {
            localStorage.setItem('token', login.token)
            dispatch(actionLogin(login.token))
            dispatch(setUserInfo(login.user))
            history.replace('/profesor')
        },
        onError: (error) => {
            setErrorLogin(error.message)
        }
    })
    if (loading) return 'Cargando...'

    const handleLogin = (values) => {
        values.idLibro = idLibro
        login({
            variables: {
                email: values.email,
                password: values.password,
                titulo: {
                    _idPlantel: values.plantel,
                    _idTitulo: values.idLibro
                }
            }
        })
    }

    return (
        <div className="custom__modal">
            <div className="title">
                <div className="text">
                    <h2>Ingresa tu cuenta</h2>
                </div>
                <button className="close" onClick={close}>
                    &times;
                </button>
            </div>
            <div className="content">
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        estado: estados[0]?._id,
                        plantel: planteles[0]?.planteles[0]?._id,
                    }}
                    validationSchema={LOGIN_VALIDATION_SCHEMA}
                    onSubmit={handleLogin}
                >
                    <Form className="Form">
                        <div className="input-icon">
                            <div>
                                <MdEmail />
                                <Field type="text" name="email" placeholder="Tu correo electronico" />
                                <ErrorMessage
                                    name="email"
                                    component="span"
                                    className="msg-error"
                                />
                            </div>
                        </div>
                        <div className="input-icon">
                            <div>
                                <RiLockPasswordFill />
                                <Field type="password" name="password" placeholder="Tu contraseña" />
                                <ErrorMessage
                                    name="password"
                                    component="span"
                                    className="msg-error"
                                />
                            </div>
                        </div>
                        <div className="input">
                            <label htmlFor="estado">Estado</label>
                            <Field as="select" name="estado" onChange={handleSelectEstado}>
                                {
                                    estados.map((estado, i) => (
                                        <option key={i} value={estado._id}>{estado.nombre.toUpperCase()}</option>
                                    ))
                                }
                            </Field>
                        </div>
                        <div className="input">
                            <label htmlFor="plantel">Plantel</label>
                            <Field as="select" name="plantel">
                                <option value="" selected>Selecciona un plantel</option>
                                {
                                    planteles[0]?.planteles.map((plantel, i) => (
                                        <option key={i} value={plantel._id}>{plantel.nombre.toUpperCase()}</option>
                                    ))
                                }
                            </Field>
                            <ErrorMessage
                                name="plantel"
                                component="span"
                                className="msg-error"
                            />
                        </div>
                        <div className="buttons-group right">
                            <button className="btn small primary" type="submit">Entrar</button>
                        </div>
                        {
                            errorLogin ? <ShowError message={errorLogin} /> : null
                        }

                    </Form>

                </Formik>
            </div>
        </div>
    )
}
