import React from 'react'
import { Nav } from './nav'
import { Profile } from './Profile'
import LOGO from 'assets/images/kdtadigital-white.png'
import './styles.css'
import { useSelector } from 'react-redux'

export const Header = () => {
    
    const {userInfo} = useSelector(state => state.user)
    
    return (
        <div className="alumno__header">
            <div className="logo">
                <img src={LOGO} alt="Logotipo Ktdra" />
            </div>
            <div className="wrapper-nav">
                <Nav />
            </div>
            <div className="profile">
                <Profile user={userInfo} />
            </div>
        </div>
    )
}
