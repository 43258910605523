import React from 'react'
import Lottie from 'react-lottie'
import animationComputer from 'assets/lotties/3046-me-at-office.json'

export default function NoSelectedExercise({ text = '' }) {

    const options = {
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
        animationData: animationComputer
    }

    return (
        <div className="component__noSelectedExercise">
            <div className="lottie">
                <Lottie className="lottie-svg" options={options} height={360} width={360} />
            </div>
            <div className="title">
                <h2>{text}</h2>
            </div>
        </div>
    )
}
