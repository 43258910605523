import React from 'react'
import CUSTOM_IMG from 'assets/images/icons/usuario-avatar.png'
import './styles.css'
import Popup from 'reactjs-popup'
import { Menu } from './Menu'
import { IoIosArrowDown } from 'react-icons/io'
import useSettings from 'hooks/useSettings'

export const Profile = ({ user }) => {

    const [getEnvDomainResources] = useSettings()
    const URL_RESOURCES = getEnvDomainResources()
    const hashURL = new Date().getTime()
    const IMG_PHOTO = (user && user.imgPerfil) ? `${URL_RESOURCES}${user.imgPerfil}` : CUSTOM_IMG

    return (
        <div className="component__headerProfile">
            <div className="profile__wrapper">
                <Popup
                    position={['top center', 'bottom right', 'bottom left']}
                    trigger={(
                        <button className="btnProfile">
                            <div className="content">
                                <div className="foto-profile">
                                    <img src={`${IMG_PHOTO}?${hashURL}`} alt="" />
                                </div>
                                <span>{`${user?.nombres ?? ''} ${user?.primerApellido ?? ''}`}</span>
                                <div className="icon-profile"><IoIosArrowDown /></div>
                            </div>
                        </button>
                    )}
                
                    closeOnDocumentClick
                    nested
                >
                    <Menu />
                </Popup>
            </div>
        </div>
    )
}
