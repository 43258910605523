import React from "react"

import Popup from "reactjs-popup"
import { CrearGrupo } from "./crearGrupo"
import { Grupos } from "./grupos"
import './style.css'


const index = ({dataGrupo, idAcademia, libro}) => {
    return (
        <div className="wrapper">
            <div className="title">
                <h2>Mis grupos</h2>
                <Popup className="modal" modal nested closeOnDocumentClick={false} trigger={<button>Crear grupo</button>}>
                    { close => (<CrearGrupo close={close} idAcademia={idAcademia} />) }
                </Popup>
            </div>
            <Grupos grupos={dataGrupo} libro={libro}/>
        </div>
    )
}

export default index