import { createStore } from "redux"
//Reducers
import rootReducer from "redux/reducers/rootReducer"

//Persist
import { persistStore} from "redux-persist"


export const store = createStore(
    rootReducer, 
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const persistor = persistStore(store)