import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

//Routes
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { AddBookRoute } from './AddBookRoute';
//Components
import { Home } from 'components/Home/Home';
import { RegisterProfesor } from 'components/Login/Profesor/RegisterProfesor';
import { MailConfirmation } from 'components/Login/Profesor/Confirmation/MailConfirmation';
import { RecoveryPassword } from 'components/Login/Profesor/Recovery/RecoveryPassword';
import { ProfesorRoutes } from './ProfesorRoutes';
import { AlumnoRoutes } from './AlumnoRoutes';
import { Testing } from 'components/Ejercicios/Testing';
// Redux
import { useSelector } from 'react-redux';
//GraphQL
import { ApolloProvider } from '@apollo/client';
import { client } from 'graphql/config';
import { Demo } from 'components/Demo';

export const AppRouter = () => {
	const { isLogged } = useSelector(state => state.auth);
	const { userInfo } = useSelector(state => state.user);

	const isAddingNewBook = userInfo !== null ? true : false;
	const role = userInfo?.rol.name ?? '';

	return (
		<ApolloProvider client={client}>
			<Router>
				<Switch>
					<PublicRoute path='/home' component={Home} isAuthorized={isLogged} />
					{isAddingNewBook ? (
						<AddBookRoute
							path='/registro/profesor/:idLibro'
							component={RegisterProfesor}
							isAddingNewBook={isAddingNewBook}
							isAuthorized={isLogged}
						/>
					) : (
						<PublicRoute
							path='/registro/profesor/:idLibro'
							component={RegisterProfesor}
							isAuthorized={isLogged}
						/>
					)}
					<PublicRoute
						path='/confirmacion/profesor/:token'
						component={MailConfirmation}
						isAuthorized={isLogged}
					/>
					<PublicRoute
						path='/pruebas/:token'
						role={role}
						component={Testing}
						isAuthorized={isLogged}
					/>
					<PublicRoute
						path='/confirmacion/alumno/:token'
						component={MailConfirmation}
						isAuthorized={isLogged}
					/>
					<PublicRoute
						path='/recuperar-contraseña/:token'
						component={RecoveryPassword}
						isAuthorized={isLogged}
					/>
					<PublicRoute
						path='/registro/profesor/:idLibro'
						component={RegisterProfesor}
						isAuthorized={isLogged}
					/>
					<PublicRoute 
						path='/demo/:idLibro'
						component={Demo}
						isAuthorized={isLogged}
					/>
					{role === 'profesor' ? (
						<PrivateRoute
							path='/'
							component={ProfesorRoutes}
							isAuthorized={isLogged}
							userInfo={userInfo}
						/>
					) : role === 'alumno' ? (
						<PrivateRoute
							path='/'
							component={AlumnoRoutes}
							isAuthorized={isLogged}
							userInfo={userInfo}
						/>
					) : null}
					<PrivateRoute
						path='/'
						component={ProfesorRoutes}
						isAuthorized={isLogged}
					/>
					<Redirect to='/home' />
				</Switch>
			</Router>
		</ApolloProvider>
	);
};
