import React from 'react'
import { MdPhotoCamera } from 'react-icons/md'

import { useMutation } from '@apollo/client'
import { CHANGE_AVATAR } from 'graphql/Mutations/mutation.commons'
import CUSTOM_IMG from 'assets/images/icons/usuario-avatar.png'
import toast from 'react-hot-toast'
import useSettings from 'hooks/useSettings'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserInfo } from 'redux/actions/actions'

/* Falta agregar el efecto de Hover */
export const FotoPerfil = ({ foto }) => {

    const dispatch = useDispatch()
    const {userInfo} = useSelector(state => state.user)
    const [getEnvDomainResources] = useSettings()
    const URL_RESOURCES = getEnvDomainResources()
    const hashURL = new Date().getTime()
    const IMG_PHOTO = foto ? `${URL_RESOURCES}${foto}` : CUSTOM_IMG


    const [updateMeAvatar] = useMutation(CHANGE_AVATAR,{
        onCompleted: ({updateMeAvatar}) => {
            if(updateMeAvatar) {
                dispatch(updateUserInfo(userInfo))
                toast.success('Foto de perfil actualizada correctamente.')
            }
        },
        onError: () => {
            toast.error('Ops! Ocurrió un error al cambiar tu foto de perfil, intentalo más tarde.')
        },
    })

    const handleOnChange = (e) => {
        updateMeAvatar({variables: { file: e.target.files[0] }})
    }

    return (
        <div className="component__fotoPerfil">
            <label htmlFor="profile_image">
                <input type="file" name="profile_image" accept="image/*" onChange={handleOnChange} id="profile_image" />
                <div className="wrapper">
                    <img src={`${IMG_PHOTO}?${hashURL}`} alt="Foto de perfil" />
                </div>
                <div className="placeholder">
                    <MdPhotoCamera />
                </div>
            </label>
        </div>
    )
}
