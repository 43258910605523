import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PublicRoute = ({ isAuthorized, role, component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			component={props =>
				isAuthorized && role === 'profesor' ? (
					<Component {...props} />
				) : !isAuthorized ? (
					<Component {...props} />
				) : (
					<Redirect to='/' />
				)
			}
		/>
	);
};
