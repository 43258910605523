import React from 'react';
import { BiAddToQueue } from 'react-icons/bi';
import Popup from 'reactjs-popup';
import { RegistroLibro } from './RegistroLibro';

export const Title = () => {
	return (
		<div className='component__title'>
			<h2>Mis libros</h2>
			<Popup
				className='modal'
				modal
				nested
				closeOnDocumentClick={false}
				trigger={
					<button style={{ zIndex: '1' }}>
						<BiAddToQueue />
						<span>Registrar token</span>
					</button>
				}
			>
				{close => <RegistroLibro close={close} />}
			</Popup>
		</div>
	);
};
