import React, { useState } from 'react'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import { CHANGE_PASSWORD_VALIDATION_SCHEMA } from 'utils/validationSchemas'
import Dialogs from 'components/Helpers/Dialogs'

import { useMutation } from '@apollo/client'
import { CHANGE_PASSWORD } from 'graphql/Mutations/mutation.commons'
import ShowError from 'components/Helpers/Form/ShowError'
import { LoaderForm } from 'components/Helpers/Loader/Loader'

export const CambiarContrasena = () => {

    const [errorMutation, setErrorMutation] = useState(null)
    const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD,{
        onCompleted: ({changePassword}) => {
            if(changePassword._id) Dialogs.Success('Contraseña actualizada', 'Tu contraseña ha sido actualizada')
            setErrorMutation(null)
        },
        onError: (error) => { setErrorMutation(error.message) }
    })

    const handleSubmit = (values) => {
        changePassword({
            variables: {
                prePassword: values.contra_anterior.trim(),
                newPassword: values.contra_nueva.trim(),
            }
        })
    }

    return (
        <div className="info-basic">
            { loading ? <LoaderForm /> : null}
            <Formik
                initialValues={{
                    contra_anterior: '',
                    contra_nueva: ''
                }}
                onSubmit={handleSubmit}
                validationSchema={CHANGE_PASSWORD_VALIDATION_SCHEMA}
            >
                <Form className="Form">
                    <div className="input">
                        <label htmlFor="contrasena_anterior">Contraseña anterior</label>
                        <Field type="password" name="contra_anterior" id="contrasena_anterior" />
                        <ErrorMessage name="contra_anterior" component="span" className="msg-error" />
                    </div>
                    <div className="input">
                        <label htmlFor="contrasena_nueva">Contraseña nueva</label>
                        <Field type="password" name="contra_nueva" id="contrasena_nueva" />
                        <ErrorMessage name="contra_nueva" component="span" className="msg-error" />
                    </div>
                    <div className="buttons-group right">
                        <button className="btn primary small" type="submit">Actualizar contraseña</button>
                    </div>
                    {
                        errorMutation ? <ShowError message={errorMutation} /> : null
                    }
                </Form>
            </Formik>
        </div>
    )
}
