import { types } from 'types/types'

const initialState = {
    isLogged: false,
    apiKey: null,
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.login:
            return {
                isLogged: true,
                apiKey: action.payload.apiKey,
            }
        case types.logout:
            return {
                isLogged: false,
                apiKey: null,
            }
        default:
            return state
    }
}