import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
//Components
import Question from './Question'
import { Figure } from './Figure'
import { WrongLetters } from './WrongLetters'
import Word from './Word'
//Hooks
import useHangMan from './hooks'
import './hangman.css'
import FinishWord from './FinishWord'
import { suffleArrayDaya } from '../hooks'


export const HangMan = ({ data = [], onFinalize, finalized = false }) => {
	const [correctLetters, setCorrectLetters] = useState([])
	const [wrongLetters, setWrongLetters] = useState([])
	const [wordIndex, setWordIndex] = useState(0)
	const [isFinish, setIsFinish] = useState({ estatus: false, isLoser: false })
	const [questionsData, setQuestionsData] = useState([]) 
	const [maxCorrecto, setmaxCorrecto] = useState(0)
	const [finishGame, setFinishGame] = useState(false)
	const [totalPoints, setTotalPoints] = useState(0)

	useEffect(() => {
		setQuestionsData([])
		data.forEach(word => {
			setQuestionsData(data => [...data, { pregunta: word.pregunta, word: word.respuesta.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") }])
		})
	}, [data])

	useEffect(() => {
		const handleKeyDown = event => {

			if (questionsData[wordIndex]) {
				const { key, keyCode } = event
				if (!isFinish.estatus && keyCode >= 65 && keyCode <= 90) {
					const letter = key.toLowerCase()
					if (questionsData[wordIndex].word.includes(letter)) {
						if (!correctLetters.includes(letter)) {
							setmaxCorrecto(maxCorrecto + (questionsData[wordIndex].word.match(new RegExp(letter, "g")) || []).length)
							setCorrectLetters(currentLetters => [...currentLetters, letter]);
						} else {
							//toast.error('Ya has ingresado esta letra.')
						}
					} else {
						if (!wrongLetters.includes(letter)) {
							setWrongLetters(currentLetters => [...currentLetters, letter]);
							//toast.error('Letra incorrecta ingresa nuevamente.')
						} else {
							//toast.error('Ya has ingresado esta letra.')
						}
					}
				}
			}

		}
		isFinish.estatus !== true && window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [correctLetters, wrongLetters, questionsData, isFinish])


	useEffect(() => {
		if (questionsData[wordIndex]) {
			if (maxCorrecto === questionsData[wordIndex].word.length) {
				setIsFinish({ estatus: true, isLoser: false })
				setTotalPoints(totalPoints + 10)
			}
			if (wrongLetters.length === 6) setIsFinish({ estatus: true, isLoser: true })
		}
	}, [maxCorrecto, wordIndex, wrongLetters])

	const handleNextWord = () => {
		setCorrectLetters([])
		setWrongLetters([])
		setmaxCorrecto(0)
		if (wordIndex <= data.length) setWordIndex(wordIndex + 1)
		setIsFinish({ estatus: false, isLoser: false })
	}
	
	useEffect(() => {
		if (wordIndex === questionsData.length && wordIndex !== 0) {
			setFinishGame(true)
			const calificacionFinal = totalPoints / questionsData.length
			onFinalize(calificacionFinal)
		}
	}, [wordIndex, questionsData])

	useEffect(() => {
		if (finalized) {
			const calificacionFinal = totalPoints / questionsData.length
			onFinalize(calificacionFinal)
		}
	}, [finalized])
	
	return (
		<div className="component__hangMan">
			<Toaster />
			{
				(questionsData.length > 0 && questionsData[wordIndex] && !finishGame) &&
				<>
					<div className="title">
						<Question text={questionsData[wordIndex].pregunta} num={wordIndex + 1} />
						<FinishWord isFinishWord={isFinish} onNextWord={handleNextWord} word={questionsData[wordIndex].word} isFinishGame={finishGame}  />
					</div>
					<div className="figure-words">
						<Figure wrongLetters={wrongLetters} />
						<Word selectedWord={questionsData[wordIndex].word} correctLetters={correctLetters} isFinishWord={isFinish} />
					</div>
				</>
			}
			<WrongLetters wrongLetters={wrongLetters} />
		</div>
	)
}