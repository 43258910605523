import React, { useMemo } from 'react'
import { Table } from './table'
import { SelectFilter } from './selectFilter';

import './styles.css'
import Excel from 'components/Helpers/Export/Excel';

export const ListAlumnos = ({alumnos}) => {

  const columns = useMemo(
      () => [
        {
          Header: "Matricula",
          accessor:  e => e.alumno.matricula === null ? 'Sin matricula' : e.matricula,
        },
        {
          Header: "Nombre",
          accessor: e => e.alumno.nombres
        },
        {
          Header: "Apellido Paterno",
          accessor: e => e.alumno.primerApellido
        },
        {
          Header: "Aapellido Materno",
          accessor: e => e.alumno.segundoApellido
        },
        {
          Header: "Estatus",
          accessor: e => e.alumno.estatus === true ? <span className="tag enabled">Activo</span> : 
                  <span className="tag disabled">Baja</span>
        },
        {
          Header: "Calificacion",
          accessor:  e => e.calificacion === null ? 'Sin calificacion' : <span className="bold">{e.calificacion.toFixed(1)}</span>,
        },
      ],
      []
    )

    

    const buttons=[<Excel data={alumnos}  />]

    return (
        <div className="list__alumnos">
            <Table columns={columns} data={alumnos} componets={buttons}/>
        </div>
    )
}
