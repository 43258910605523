import React, { useEffect} from 'react'
import { useParams } from 'react-router'

//GraphQL
import { useMutation } from '@apollo/client'
import { CONFIRM_EMAIL } from 'graphql/Mutations/mutation.commons'
import { LoaderPage } from 'components/Helpers/Loader/Loader'
import { useDispatch } from 'react-redux'
import { actionLogin, setUserInfo } from 'redux/actions/actions'

export const MailConfirmation = () => {

    let {token} = useParams()
    const dispatch = useDispatch()

    const [confirmationEmailByToken, {loading}] = useMutation(CONFIRM_EMAIL,{
        onCompleted: ({confirmationEmailByToken}) => {
            localStorage.setItem('token', confirmationEmailByToken.token)
            dispatch(actionLogin(confirmationEmailByToken.token))
            dispatch(setUserInfo(confirmationEmailByToken.user))
        }
    })

    useEffect(() => {
        confirmationEmailByToken({variables:{token}})
    }, [token])

    if(loading) return <LoaderPage/>

    return (
        <div>
        </div>
    )
}
