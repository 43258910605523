import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Popup from 'reactjs-popup'
import storage from 'redux-persist/lib/storage'
import { client } from 'graphql/config'
//Components
import { Configuracion, Fag } from './Configuracion'
//Redux
import { useDispatch } from 'react-redux'
import { actionLogout } from 'redux/actions/actions'
//Hooks
import useSettings from 'hooks/useSettings'

import { RiSettings5Fill } from 'react-icons/ri'
import { ImExit } from 'react-icons/im'
import { IoIosHelpCircle } from 'react-icons/io'
import AVATAR_DEFAULT from 'assets/images/icons/usuario-avatar.png'

export const MiPerfil = ({ data }) => {

    const [getEnvDomainResources] = useSettings()
    const URL_RESOURCES = getEnvDomainResources()
    const hashURL = new Date().getTime()
    const avatarProfile = data.imgPerfil ? `${URL_RESOURCES}${data.imgPerfil}` : AVATAR_DEFAULT
    
    return (
        <div className="component__miPerfilProfesor">
            <Popup
                nested
                className="float-modal"
                trigger={<button className="profile">
                    <div className="avatar-img">
                        <img src={`${avatarProfile}?${hashURL}`} alt="" />
                    </div>
                    <div className="name">
                        {`${data.nombres} ${data.primerApellido}`}
                    </div>
                </button>}
                position={['top center', 'bottom right', 'bottom left']}
                closeOnDocumentClick
            >
                <ModalFloat dataUser={data}/>
            </Popup>
        </div>
    )
}


const ModalFloat = ({ dataUser}) => {

    const history = useHistory()
    const dispatch = useDispatch()

    const handleLogout = () => {
        client.clearStore().then(() => {
            client.resetStore();
            dispatch(actionLogout())
            storage.removeItem('persist:root')
            localStorage.removeItem('token')
            history.replace('/home')
        });
    }
    
    return (
        <div className="content_ModalFloat">
            <div className="items-list">
                <Popup className="modal" modal nested closeOnDocumentClick={false} trigger={<button><RiSettings5Fill />  Configuración</button>}>
                    {close => (<Configuracion dataUser={dataUser} close={close}/>)}
                </Popup>

                <Popup className="modal" modal nested closeOnDocumentClick={false} trigger={<button><IoIosHelpCircle />  Ayuda y asistencia</button>}>
                    {close => (<Fag close={close}/>)}
                </Popup>

                
                <button onClick={handleLogout}><ImExit />  Salir</button>
            </div>
            <div className="version">
                Ktdra Digital v1.0.1
            </div>
        </div>
    )
}

