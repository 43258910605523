const useSettings = () => {
    const { REACT_APP_NODE_ENV, REACT_APP_DOMAIN_RESOURCE, 
            REACT_APP_DOMAIN_TEST_RESOURCE } = process.env

    const getEnvDomainResources = () => {
        switch(REACT_APP_NODE_ENV) {
            case 'dev': return REACT_APP_DOMAIN_TEST_RESOURCE;
            case 'prod': return REACT_APP_DOMAIN_RESOURCE
            default: return REACT_APP_DOMAIN_TEST_RESOURCE
        }
    }

    return [getEnvDomainResources]
}

export default useSettings