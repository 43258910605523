import './ViewLibro.css';

const ViewLibro = ({ book }) => {
	return (
		<div className='component__modalSelectBook'>
			<div className='title'>
				<h2>{book.nombre}</h2>
				<div className='content'>
					<div className='books-items'>
						<div className='book'>
							<img
								src={`${process.env.REACT_APP_DOMAIN_RESOURCE + book.portada}`}
								alt=''
							/>
						</div>
						<div className='content__info'>
							<div className='content__info-text'>
								Abreviación :<p>{book.abrev}</p>
							</div>
							<div className='content__info-text'>
								Autor:
								<p>{book.autor}</p>
							</div>

							<div className='content__info-text'>
								Descripción:
								<p>{book.descripcion}</p>
							</div>
							<div className='content__info-text'>
								Semestre:
								<p>{book.semestre}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewLibro;
