import { gql } from '@apollo/client'

export const LOGIN = gql`
    mutation login (
        $email: String!,
        $password: String!
        $titulo: TituloLogin
    ){
        login(
            email: $email,
            password: $password,
            titulo: $titulo
        ){
            token,
            user{
               _id,
               email,
               nombres,
               primerApellido,
               segundoApellido,
               imgPerfil,
               telefono,
               matricula,
               rol{
                   _id,
                   name
               } 
            }
        }
    }
`
export const CONFIRM_EMAIL = gql`
    mutation confirmationEmailByToken(
        $token: String!
    ){
        confirmationEmailByToken(
            token: $token
        ){
            token,
            user{
               _id,
               nombres,
               primerApellido,
               segundoApellido,
               imgPerfil,
               rol{
                   _id,
                   name
               } 
            }
        }
    }
`

export const RECOVERY_PASSWORD = gql`
    mutation verifyForgotPassword(
        $token: String!,
        $newPassword: String!
    ){
        verifyForgotPassword(
            token: $token,
            newPassword: $newPassword
        )
    }
`

export const CHANGE_PASSWORD = gql`
    mutation changePassword(
        $prePassword: String!,
        $newPassword: String!
    ){
        changePassword(
            prePassword: $prePassword,
            newPassword: $newPassword
        ){
            _id
        }
    }
`

export const CHANGE_AVATAR = gql`
    mutation updateMeAvatar($file: Upload!){
        updateMeAvatar(file: $file)
    }
`