import { gql } from '@apollo/client'

export const REGISTRO_PROFESORES = gql`
    mutation registerProfesor(
        $email: String!,
        $password: String,
        $telefono: String,
        $nombres: String,
        $primerApellido: String,
        $segundoApellido: String,
        $_idTitulo: ID,
        $_idPlantel: ID,
    ){
        registerProfesor(
            data:{
                email: $email,
                password: $password,
                telefono: $telefono,
                nombres: $nombres,
                primerApellido: $primerApellido,
                segundoApellido: $segundoApellido,
                _idTitulo: $_idTitulo,
                _idPlantel: $_idPlantel,

            }
        ){
            _id,
        }
    }
`
export const REGISTRO_ALUMNOS = gql`
    mutation registerAlumno(
        $email: String,
        $password: String,
        # $telefono: String,
        $nombres: String,
        $primerApellido: String,
        $segundoApellido: String,
        $token: String,
    ){
        registerAlumno(
            data:{
                email: $email,
                password: $password,
                # telefono: $telefono,
                nombres: $nombres,
                primerApellido: $primerApellido,
                segundoApellido: $segundoApellido,
                token: $token,
            }
        ){
            _id,
        }
    }
`

export const RECUPERAR_CONTRASENA = gql`
    mutation forgotPasswordSendEmail(
        $email: String!
    ){
        forgotPasswordSendEmail(email: $email){
            _id
        }
    }
`
