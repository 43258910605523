import { AiOutlineQuestion } from "react-icons/ai"
import { FiColumns } from "react-icons/fi"
import { ImCheckboxUnchecked } from "react-icons/im"
import { VscPerson } from "react-icons/vsc"

export const useOptionsSelect = (libro, inProcess, temasInactivos) => {
    
    const getOptions = libro.hijos.map((element) => {
        let items = []
        element.hijos.map(e => items.push({
            value: e._id,
            label: `${e.seccion} - ${e.nombre}`,
            isDisabled: temasInactivos.includes(e._id) 
        }))
        return { label: `${element.seccion} - ${element.nombre}`, options: items }
    })

    let defaultValue = ''
    getOptions.forEach(element => {
        return element.options.map(e => { if (e.value === inProcess) defaultValue = e })
    })

    
    
    return [getOptions, defaultValue]

}

export const useFormatExercise = (tipo) => {

    let format = ''
    
    switch(tipo) {
        case 'memorama' : format = { icon: <ImCheckboxUnchecked />, text: 'Memorama', color: '#118ab2' } 
            break
        case 'quiz': format = { icon: <AiOutlineQuestion />, text: 'Cuestionario', color: '#e36414' }
            break
        case 'ahorcado': format = { icon: <VscPerson />, text: 'Ahorcado', color: '#2b2d42'  }
            break
        case 'relCol': format = { icon: <FiColumns />, text: 'Relacionar columnas', color: '#3c096c' }
            break
        default: format = { icon: '', text: '', color: '#fff' }
            break
    }

    return [format]
}