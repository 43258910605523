import { gql } from '@apollo/client'

export const CREAR_GRUPO = gql`
    mutation createGrupo(
        $_idAcademia: ID!
        $nombre: String
        $cupo: Int
    ){
        createGrupo(
            data:{
                _idAcademia: $_idAcademia,
                nombre: $nombre,
                cupo: $cupo
            }
        ){
            _id
        }
    }
`

export const DELETE_GRUPO = gql`
    mutation deleteGrupo($_idGrupo: ID!){
        deleteGrupo(_idGrupo: $_idGrupo)
    }
`

export const CHANGE_ESTATUS_GRUPO = gql`
    mutation disableGrupo($_idGrupo: ID!, $estatus: Boolean!) {
        disableGrupo(_idGrupo: $_idGrupo, estatus: $estatus)
    }
`

export const UPDATE_INFO_PROFESOR = gql`
    mutation updateMeInfo(
        $nombres: String,
        $primerApellido: String,
        $segundoApellido: String,
        $email: String,
        $telefono: String
    ){
        updateMeInfo(data: {
            nombres: $nombres,
            primerApellido: $primerApellido,
            segundoApellido: $segundoApellido,
            email: $email,
            telefono: $telefono
        }){
            _id,
            email,
            nombres,
            primerApellido,
            segundoApellido,
            imgPerfil,
            telefono,
            matricula,
            rol{
                _id,
                name
            }
        }
    }
`

export const DISABLE_TEMA_BY_GRUPO = gql`
    mutation disableTemaByGrupo(
        $_idGrupo: ID!
        $_idTema: ID!,
        $estatus: Boolean!
    ){
        disableTemaByGrupo(
            _idGrupo: $_idGrupo,
            _idTema: $_idTema,
            estatus: $estatus
        )
    }
`

export const SET_PROCESS_TEMA = gql`
    mutation setTemaInProcessByGrupo($_idGrupo: ID!, $_idTema: ID!) {
        setTemaInProcessByGrupo(_idGrupo: $_idGrupo, _idTema: $_idTema)
    }
`