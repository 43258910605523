import React from 'react'
import { IoMdHelp } from 'react-icons/io'
import Popup from 'reactjs-popup'
import './styles.css'



/**
 * Component for showing details of the user.
 *
 * @component
 * @example
 * const age = 21
 * const name = 'Jitendra Nirnejak'
 * return (
 *   <User age={age} name={name} />
 * )
 */

export const HelpComponent = ({ title, message }) => {
    return (
        <Popup
            className="popup-help"
            trigger={<div className="component__helpComponent"><span><IoMdHelp /></span></div>}
            position="bottom center"
            closeOnDocumentClick
            on={['click', 'hover']}
        >
            <div className="content">
                {title && <div className="title">{title}</div>}
                <p>{message}</p>
            </div>
        </Popup>
    )
}
