import React  from 'react'
import { useSelector } from 'react-redux'
//Components
import { FotoPerfil } from './FotoPerfil'
// import { AiFillEdit } from 'react-icons/ai'
// import { GoCircleSlash } from 'react-icons/go'
// import { IoIosSave } from 'react-icons/io'



export const InfoPerfil = () => {

    const {userInfo} = useSelector(state => state.user)
    // const [showEdit, setShowEdit] = useState({ hide: 'hidden', show: '' })
    // const showEditProfile = () => setShowEdit({ hide: '', show: 'hidden' })
    // const hiddenEditProfile = () => setShowEdit({ hide: 'hidden', show: '' })

    return (
        <div className="component__infoPerfil">
            <div className="photo__profile">
                <FotoPerfil foto={userInfo?.imgPerfil ?? ''}  />
            </div>
            <div className="info__profile">
                <h2>{`${userInfo?.nombres ?? ''} ${userInfo?.primerApellido ?? ''} ${userInfo?.segundoApellido ?? ''}`}</h2>
               {/*  <div className="alias_profile">
                    <div className={`show ${showEdit.show}`}>
                        <span>@arturoesp</span>
                        <button onClick={showEditProfile} className="edit_btn">
                            <AiFillEdit />
                        </button>
                    </div>
                    <div className={`edit ${showEdit.hide}`}>
                        <input type="text" value="@arturoesp" />
                        <div className="options">
                            <button><IoIosSave /></button>
                            <button onClick={hiddenEditProfile}><GoCircleSlash /></button>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
