import React, { useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { FRM_RECOVERY_PASSWORD_VALIDATION_SCHEMA } from 'utils/validationSchemas'
import { useHistory, useParams } from 'react-router'
//Components
import { LoaderPage } from 'components/Helpers/Loader/Loader'
//GraphQL
import { useMutation } from '@apollo/client'
import { RECOVERY_PASSWORD } from 'graphql/Mutations/mutation.commons'

import EXITO_SVG from 'assets/images/icons/exito.svg'
import './style.css'
import { RiLockPasswordFill } from 'react-icons/ri'

export const RecoveryPassword = () => {

    const history = useHistory()
    const [successUpdatePassword, setSuccessUpdatePassword] = useState(false)

    let { token } = useParams()
    const [verifyForgotPassword, { loading }] = useMutation(RECOVERY_PASSWORD, {
        onCompleted: ({ verifyForgotPassword }) => {
            if (verifyForgotPassword) {
                history.replace('/home')
            }
        }
    })

    if (loading) return <LoaderPage />

    const handleRecoveryPassword = (values) => {
        verifyForgotPassword({
            variables: {
                token,
                newPassword: values.password
            }
        })
    }

    return (
        <div className="component__recoveryPassword">
            <div className="form-recovery">
                <div className="title">
                    <div className="icon"><RiLockPasswordFill /></div>
                    <h2>Reiniciar contraseña</h2>
                </div>
                <Formik
                    initialValues={{
                        password: '',
                    }}
                    validationSchema={FRM_RECOVERY_PASSWORD_VALIDATION_SCHEMA}
                    onSubmit={handleRecoveryPassword}
                    autoComplete="off"
                >
                    <Form className="Form">
                        <div className="input-group">
                            <div className="input">
                                <label htmlFor="password">Contraseña</label>
                                <Field type="password" id="password" name="password" />
                                <ErrorMessage
                                    name="password"
                                    component="span"
                                    className="msg-error"
                                />
                            </div>
                        </div>
                        <div className="buttons-group center">
                            <button className="btn primary" type="submit">Recuperar contraseña</button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

