import React from 'react'
import InfoLibro, { LoadingInfoLibro } from './InfoLibro'

import './styles.css'

export default function DetallesLibro({ data = {}, loading = false }) {

    const { titulo, profesor, grupo } = data

    if (loading) return (
        <div className="component">
            <LoadingInfoLibro />
        </div>
    )
    else
        return (
            <div className="component__detallesLibro">
                <InfoLibro titulo={titulo} profesor={profesor} grupo={grupo} loading={loading} />
            </div>
        )
}
