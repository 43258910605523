import React from 'react'
import ProgressBar from '@ramonak/react-progress-bar'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { setPortEvids, setTema } from 'redux/actions/actions'

export const Libro = ({ data }) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const PORTADA_IMG = `${process.env.REACT_APP_DOMAIN_RESOURCE}${data?.portada ?? ''}`
    const classComponent = data?.estatus === true ? 'component__selectLibro noselect' : 'component__selectLibro noselect disabled'

    const handleHistoryBook = (_id) => {
        dispatch(setPortEvids(_id))
        dispatch(setTema(''))
        history.replace('/alumno/libro')
    }
    
    return (
        <div className={classComponent}>
            <div className="content">
                <div className="portada-img">
                    <img src={PORTADA_IMG} alt="Portada" />
                </div>
                <div className="title">
                    <h2>{data?.titulo ?? ''}</h2>
                    <span className="autor">{data?.autor}</span>
                </div>
                <div className="porcentage">
                    <ProgressBar
                        height="10px"
                        borderRadius="5px"
                        isLabelVisible={false}
                        bgColor="#2C65FF"
                        completed={data?.avance ?? 0} />
                </div>
                {
                    data?.estatus === true ? <button onClick={() => handleHistoryBook(data?._id ?? '', data?.temasOnProces)}>Seleccionar</button> : <span>GRUPO DESACTIVADO</span>
                }
            </div>
        </div>
    )
}
