import React, { useEffect, useState } from 'react'

import { Timer } from './Timer'
import { formatStringTimer } from './hooks'

import './styles.css'

export const BombaTiempo = ({ onFinalize, stateExercise = {} }) => {
    
    const [stateTimer, setStateTimer] = useState(stateExercise.time)

    useEffect(() => {
        if (stateExercise.time) setStateTimer(stateExercise.time)
    }, [stateExercise])

    useEffect(() => {
        if (stateExercise.started && !stateExercise.finalized) {
            const timer = stateTimer > 0 && setInterval(() => setStateTimer(stateTimer - 1), 1000)
            return () => clearInterval(timer)
        }
    }, [stateTimer, stateExercise])
  

    if (stateTimer === 0 && stateExercise.isTimer) onFinalize() 
    
    return (
        (stateExercise.isTimer && stateTimer > 0) ? <div className="component__bombaTiempo noselect">
            <div className="content">
                <p>{stateExercise.started ? 'Tiempo restante' : 'Tiempo disponible'}</p>
                <Timer time={formatStringTimer(stateTimer)} started={stateExercise.started} finalized={stateExercise.finalized} />
            </div>
        </div> : <></>
    )

}
