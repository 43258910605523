import React from 'react'
import { useDispatch } from 'react-redux'
import { setTema } from 'redux/actions/actions'

import { FcCheckmark, FcCancel } from 'react-icons/fc'

export const ItemTema = ({ tema = {} }) => {

    const { _id, nombre, disponible, calificacion, num } = tema

    return (
        <div className="c__itemTema" style={{ cursor: 'pointer' }}>
            <div className="title">
                <span className="tema">
                    {`${num}`}
                    <span>{nombre}</span>
                </span>
                <div className="status">
                    { disponible ? <span className='in-progress'>En curso</span> : <span className="score">{ calificacion === 0 ? 0 : calificacion.toFixed(1) }</span> }
                </div>
            </div>
        </div>
    )
}
