import React, { useState } from 'react'
import { Field, Form, Formik } from 'formik'
//Components
import Dialogs from 'components/Helpers/Dialogs'
import ShowError from 'components/Helpers/Form/ShowError'
import { CambiarAvatar } from './cambiarAvatar'
import { LoaderForm } from 'components/Helpers/Loader/Loader'
//GraphQL
import { useMutation } from '@apollo/client'
import { UPDATE_INFO_PROFESOR } from 'graphql/Mutations/mutation.profesor'
//Redux
import { useDispatch } from 'react-redux'
import { updateUserInfo } from 'redux/actions/actions'


export const InformacionBasica = ({data}) => {

    const [errorUpdate, setErrorUpdate] = useState(null)
    const dispatch = useDispatch()
    
    const[updateMeInfo, { loading }] = useMutation(UPDATE_INFO_PROFESOR,{
        onCompleted: ({updateMeInfo}) => {
            if(updateMeInfo._id) Dialogs.Success('Actualización correcta', 'Tu información se actualizo.')
            setErrorUpdate(null)
            dispatch(updateUserInfo(updateMeInfo))
        },
        onError: (error) => {setErrorUpdate(error.message)},
    })

    const handleSubmit = (values) => {
        const data = {
            nombres: values.nombres,
            primerApellido: values.a_paterno,
            segundoApellido: values.a_materno,
            email: values.email,
            telefono: values.telefono
        }
        updateMeInfo({variables: data})
    }
    
    return (
        <div className="info-basic">
            {loading ? <LoaderForm /> : null}
            <div className="avatar">
                <CambiarAvatar msgError={setErrorUpdate} avatar={data.imgPerfil} />
            </div>
            <div className="form-basic">
                <Formik
                    initialValues={{
                        nombres: data?.nombres ?? '',
                        a_paterno: data?.primerApellido ?? '',
                        a_materno: data?.segundoApellido ?? '',
                        email: data?.email ?? '',
                        telefono: data?.telefono ?? ''
                    }}
                    onSubmit={handleSubmit}
                >
                    <Form className="Form">
                        <div className="input">
                            <label htmlFor="nombres">Nombre</label>
                            <Field type="text" name="nombres" id="nombres" />
                        </div>
                        <div className="input-group">
                            <div className="input">
                                <label htmlFor="a_paterno">Apellido paterno</label>
                                <Field type="text" name="a_paterno" id="a_paterno" />
                            </div>
                            <div className="input">
                                <label htmlFor="a_materno">Apellido materno</label>
                                <Field type="text" name="a_materno" id="a_materno" />
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input">
                                <label htmlFor="email">Correo electronico</label>
                                <Field type="text" name="email" id="email" />
                            </div>
                            <div className="input">
                                <label htmlFor="telefono">Teléfono</label>
                                <Field type="text" name="telefono" id="telefono" />
                            </div>
                        </div>
                        <div className="buttons-group right">
                            <button className="btn primary small" type="submit">Actualizar información</button>
                        </div>
                        { errorUpdate ? <ShowError message={errorUpdate} /> : null }
                    </Form>
                </Formik>
            </div>
        </div>
    )
}
