import * as Yup from 'yup'
import { REGEX_EMAIL, REGEX_PASSWORD } from './regex'

export const REGISTER_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Debes ingresar tu nombre'),
    a_paterno: Yup.string().required('Debes ingresar tu apellido paterno'),
    a_materno: Yup.string().required('Debes ingresar tu apellido materno'),
    email: Yup.string().required('Debes ingresar un email').matches(REGEX_EMAIL, 'Debes ingresar un email valido'),
    password: Yup.string().required('Debes ingresar una contraseña').matches(REGEX_PASSWORD, 'La contraseña debe tener al menos 8 caracteres alfanumérico.'),
    plantel: Yup.string().required('Debes seleccionar un plantel'),
})

export const LOGIN_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string().required('Ingresa tu correo electrónico').matches(REGEX_EMAIL, 'Debes ingresar un email valido'),
    password: Yup.string().required('Ingresa tu contraseña'),
    plantel: Yup.string().required('Selecciona tu plantel'),
})

export const FRM_LOGIN_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string().required('Ingresa tu correo electrónico').matches(REGEX_EMAIL, 'Debes ingresar un email valido'),
    password: Yup.string().required('Ingresa tu contraseña'),
})

export const FRM_RECOVERY_PASSWORD_VALIDATION_SCHEMA = Yup.object().shape({
    password: Yup.string().required('Ingresa tu contraseña').matches(REGEX_PASSWORD, 'La contraseña debe tener al menos 8 caracteres, una mayuscula, una minuscula y un numero'),
})

export const CHANGE_PASSWORD_VALIDATION_SCHEMA = Yup.object().shape({
    contra_anterior: Yup.string().required('Ingresa tu contraseña.'),
    contra_nueva: Yup.string().required('Ingresa la nueva contraseña.').matches(REGEX_PASSWORD, 'La contraseña debe tener al menos 8 caracteres, una mayuscula, una minuscula y un numero'),

})

export const FRM_REGISTER_ALUMNO_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Debes ingresar tu nombre.'),
    a_paterno: Yup.string().required('Debes ingresar tu apellido paterno.'),
    a_materno: Yup.string().required('Debes ingresar tu apellido materno.'),
    email: Yup.string().required('Debes ingresar un email.').matches(REGEX_EMAIL, 'Debes ingresar un email valido'),
    password: Yup.string().required('Debes ingresar una contraseña.').matches(REGEX_PASSWORD, 'La contraseña debe tener al menos 8 caracteres alfanumérico.'),
    token: Yup.string().required('Debes ingresar un token.'),
})

export const INFORMATION_ALUMNO_SCHEMA = Yup.object().shape({
    nombres: Yup.string().required('Ingresa tu nombre completo.'),
    primerApellido: Yup.string().required('Ingresa tu apellido paterno.'),
    segundoApellido: Yup.string().required('Ingresa tu apellido materno.'),
    email: Yup.string().required('Ingresa tu correo electronico.'),
    password: Yup.string().matches(REGEX_PASSWORD, 'La contraseña debe tener al menos 8 caracteres alfanumérico.'),
})
