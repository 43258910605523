import React, { useEffect, useState } from 'react'
//Components
import Indice from './Indice'
import { LoaderSection } from 'components/Helpers/Loader/Loader'
import { Ejercicios } from 'components/Ejercicios/Ejercicios'

import { useDataLibroByIdPortEvd, useDataPortafolioEvidenciaById, useListEjerciciosByTema, useTemasInactivosByIdPortEvd, useTemasOnProcessByIdPortEvd } from './hooks'

import { useSelector } from 'react-redux'
import './styles.css'
import DetallesLibro from './DetallesLibro'

import { useDispatch } from 'react-redux'
import { setTema } from 'redux/actions/actions'

export const Libros = () => {

    const { idPortEvids } = useSelector(state => state.portEvids)
    const { idTema } = useSelector(state => state.ejercicios)

    const dispatch = useDispatch()

    const [listEjercicios, loadingListEjercicios, errorListEjercicios, handleResetListEjercicios] = useListEjerciciosByTema(idTema)

    const [dataLibro, loadingLibro, errorLibro, handleReset] = useDataLibroByIdPortEvd(idPortEvids)
    const [temasEnProceso, loadingEnProceso, errorEnProceso, handleResetTemasOnProcess] = useTemasOnProcessByIdPortEvd(idPortEvids)
    const [temasInactivos, loadingInactivos, errorInactivos, handleResetTemasInactivo] = useTemasInactivosByIdPortEvd(idPortEvids)

    const [dataPortafolioEvid, loadingPortafolioEvid, errorPortafolioEvid] = useDataPortafolioEvidenciaById(idPortEvids)

    useEffect(() => {
        if (temasEnProceso) dispatch(setTema(temasEnProceso[0]))
    }, [temasEnProceso]) 

    const handleResetIndice = () => {
        handleReset()
        handleResetTemasOnProcess()
        handleResetTemasInactivo()
    }

    return (
        <div className="alumnos__libros space">
            <div className="wrapper__indice">
                <DetallesLibro data={dataPortafolioEvid} loading={loadingPortafolioEvid} />
                <Indice temasEnProceso={temasEnProceso} temasInactivos={temasInactivos} libro={dataLibro} 
                    loading={loadingLibro || loadingEnProceso || loadingInactivos} onReset={() => handleResetIndice()}  /> 
            </div>
            <div className="wrapper__ejercicio">
                {
                    (loadingListEjercicios) ? <LoaderSection/> :
                    <Ejercicios ejercicios={listEjercicios} onResetListExercises={handleResetListEjercicios} />
                }
            </div>
        </div>
    )
}
