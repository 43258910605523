import { LoaderPage } from 'components/Helpers/Loader/Loader';
import { Book } from 'components/Profesor/Recursos/Book';
import { Recurso } from 'components/Profesor/Recursos/recurso';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import './demo.css';
import IMGLOGO from '../../assets/images/kdtadigital-white.png';
import { useBook, useIndice } from './hooks';
import Popup from 'reactjs-popup';
import { ModalSelectBook } from 'components/Profesor/Header/ModalSelectBook';
import ViewLibro from './ViewLibro';

export const Demo = () => {
	//Modo 1: Construccion (No aparece)
	//Modo 2: Demo (Aparece)
	//Modo 3: Produccion (No aparece)
	let { idLibro } = useParams();
	const history = useHistory();

	const { book, loading, error } = useBook(idLibro);
	const { indice, loadingIndice, errorIndice } = useIndice(idLibro);

	useEffect(() => {
		if (book.mode !== undefined) {
			if (book.mode !== 2) {
				history.push(`/registro/profesor/${idLibro}`)
			}
		}
	}, [book, idLibro])

	if (loading || loadingIndice) return <LoaderPage />;
	if (error || errorIndice) return <p>{`${error.message || errorIndice.message}`}</p>;

	return (
		<div className='profesor__main'>
			<div className='component__headerProfesor header'>
				<div className='book'>
					<div className='icon'>
						<img alt='imagen logo' src={IMGLOGO} />
					</div>
				</div>
				<div className='profile'>
					<div className='component__miPerfilProfesor'>
						<Popup
							className='modal'
							modal
							trigger={
								<button className='profile'>
									<div className='avatar-img'>
										<img
											alt='img book libro'
											src={`${process.env.REACT_APP_DOMAIN_RESOURCE + book.portada}`}
										/>
									</div>
									<div className='name'>{book.nombre}</div>
								</button>
							}
						>
							{close => <ViewLibro book={book} />}
						</Popup>
					</div>
				</div>
			</div>
			<div class='main__content'>
				<div className='component__recursos'>
					<Helmet>
						<meta charSet='utf-8' />

						<title>Recursos - Ktdra Digital</title>
					</Helmet>
					{/*----------  */}
					<div className='wrapper_recursos'>
						<div>
							<div className='title__content'>
								<h2>Recursos disponibles</h2>
								<p>Accede a los ultimos recursos del libro.</p>
							</div>
							<div className='container-items'>
								{book?.recursos?.map((recurso, index) => (
									<Recurso key={index} recurso={recurso} />
								))}
							</div>
						</div>
					</div>
					{/* ----------- */}

					<div className='wrapper_recursos'>
						<div>
							<div className='title__content'>
								<h2>Libro digital</h2>
								<p>Accede al libro digital en cualquier momento.</p>
							</div>
						</div>
						<div className='container-items'>
							<Book libro={indice} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
