import React, { useEffect, useState } from 'react'
import ClockTimer from 'assets/lotties/46436-sand-clock-timer.json'
import Lottie from 'react-lottie'

export const Timer = ({ time = 0, started = false, finalized = false }) => {

    const options = {
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
        animationData: ClockTimer
    }

    return (
        <div className="component__timer">
            <div className="lottie">
                { (started && !finalized) && <Lottie className="lottie-svg" options={options} height={44} width={44} /> }
            </div>
            <span>
                {((started || time !== 0) && !finalized) ? time : <span className='time-off'>AGOTADO</span>}
            </span>
        </div>
    )
}
