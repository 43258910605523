import React from 'react'
import PORTADA_EXAMPLE from 'assets/images/portada-example.jpg'

export const Portada = () => {
    return (
        <div className="component__portadaAlumno">
            <img src={PORTADA_EXAMPLE} alt="Portada" />
        </div>
    )
}
