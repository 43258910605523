import React from 'react'
import { REGEX_ACENTOS } from 'utils/regex'

const Word = ({ selectedWord = '', correctLetters = [], isFinishWord = { } }) => {
    const word = selectedWord.normalize('NFD').replace(REGEX_ACENTOS, "")
    
    return (
        <div className="component__word noselect" id="word">
            {
                word.split('').map((letter, index) => (
                    <span className="letter" key={index}>
                        { correctLetters.includes(letter) ? letter : ''}
                    </span>
                ))
            }
            {
                isFinishWord.estatus === true ? isFinishWord.isLoser === false ? 
                    <span className="good">¡Palabra correcta!</span> : <span className="bad">¡Palabra incorrecta!</span> : null
            }
        </div>
    )
}

export default Word
