import { useQuery } from "@apollo/client"
import { GET_PORTAFOLIO_EVIDENCIAS } from "graphql/Queries/queries.alumno"
import { useState, useEffect } from "react"


export const useMyPortafolioEvidencia = () => {
    const [portafolioEvidencia, setPortafolioEvidencia] = useState([])

    const { data, loading: loadingPE, error: errorPE } = useQuery(GET_PORTAFOLIO_EVIDENCIAS, {
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        if (data) setPortafolioEvidencia(data.getMyPortafoliosEvidencias)
    }, [data])

    return [portafolioEvidencia, loadingPE, errorPE]
}