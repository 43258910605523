import React from 'react'
//Components
import { ListLibros } from './ListLibros'
import { LoaderSection } from 'components/Helpers/Loader/Loader'
import { Title } from './title'
//GraphQL
import { useQuery } from '@apollo/client'
import { GET_PORTAFOLIO_EVIDENCIAS } from 'graphql/Queries/queries.alumno'
import './styles.css'

export const Libros = () => {

    const dataPE = useQuery(GET_PORTAFOLIO_EVIDENCIAS)
    const portafoliosEvidencia = dataPE.data?.getMyPortafoliosEvidencias
    if(dataPE.loading) return <LoaderSection />
    return (
        <div className="alumno__dashboard-libros">
            <Title />
            <ListLibros portafoliosEvidencia={portafoliosEvidencia} />
        </div>
    )
}
