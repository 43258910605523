const useRelCol = () => {

    const getScoreFinal = (ref, maxColumns) => {
        let correctAnswers = 0
        Object.values(ref).forEach((elemento, index) => {
            if (elemento.current.respuestaCorrecta) {
                if (elemento.current.respuestaCorrecta === true) correctAnswers = correctAnswers + 1
            }
        })
        return correctAnswers / maxColumns * 10
    }

    return [getScoreFinal]

}


export const colorsAnswers = ["#1b4332", " #7f5539", "#d62828", "#003049", "#00afb9", "#ffba08", "#A569BD", "#001219",
"#4c5c68", "#ee6c4d", "#774e24", "#136f63", "#5d4954", "#ad2e24", "#058e3f", "#403d39"]

export default useRelCol