import { gql } from '@apollo/client'


export const GET_PORTAFOLIO_EVIDENCIAS = gql`
    query getMyPortafoliosEvidencias {
        getMyPortafoliosEvidencias {
            _id,
            grupo {
                _id,
                nombre,
                estatus
            }
            profesor,
            grupo{
                enProceso
            },
            titulo {
                _id,
                nombre,
                abrev,
                descripcion,
                portada,
                autor
            },
            porcentajeAvance
        }
    }
`

export const GET_TEMAS_ON_PROCESS = gql`
    query getPortafolioEvidenciaById($_id: ID!){
        getPortafolioEvidenciaById(_id: $_id){
            grupo{
                enProceso
            }
        }
    }
`

export const GET_TEMAS_INACTIVOS = gql`
    query getPortafolioEvidenciaById($_id: ID!){
        getPortafolioEvidenciaById(_id: $_id) {
            grupo{
                temasInactivos
            }
        }
    }
`


export const GET_CALIFICACIONES_BY_PORTAFOLIOE = gql`
    query getCalificacionesByPortafolio($_idPortafolio: ID!) {
        getCalificacionesByPortafolio(_idPortafolio : $_idPortafolio){
            _id
            nombre
            calificacion
            descripcion
                hijos{
                _id
                nombre 
                calificacion
                hijos{
                    _id
                    nombre
                    calificacion
                    hijos{
                        _id
                        nombre
                        calificacion
                    }
                }
            }
        }
    }
`
export const GET_EJERCICIOS_ALUMNO_BY_TEMA = gql`
    query getCalificacionesAlumnoByTema(
        $_idTema: ID!,
    ){
        getCalificacionesAlumnoByTema(
            _idTema: $_idTema
        ){
            _id,
            nombre,
            numero,
            calificacion
        }
    }
`
export const GET_EJERCICIO_BY_ID = gql`
    query getEjercicioById(
        $_id: ID!
    ){
        getEjercicioById(
            _id: $_id
        ){
            _id,
            tipo{
                name
            },
            instruccion,
            tiempoS,
            recursos{
                nombre,
                url,
                tipo
            },
            cuerpo{
                ... on Memorama {_id, palabra,
                        recursos {_id, nombre, tipo, url} 
                    }
            ... on Quiz {_id, pregunta,
                        recursos {_id, nombre, tipo, url},
                        respuestas {_id, respuesta, correcta,
                    recursos {_id, nombre, tipo, url}
                    }
                    }
            ... on Ahorcado {_id, pregunta, respuesta}
            ... on RelCol {_id, columna1, columna2
                        recursos {_id, nombre, tipo, url}
                    }
            }
        }
    }
`

export const GET_PORTAFOLIO_EVIDENCIAS_BY_ID = gql`
    query getPortafolioEvidenciaById($_id: ID!) {
        getPortafolioEvidenciaById(_id: $_id) {
            titulo {
                nombre
                portada
                semestre
                autor  
            }
            profesor
            grupo {
                nombre
            }
            porcentajeAvance
        }
    }

`