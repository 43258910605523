import { types } from 'types/types'

const initialState = {
    userInfo: null,
}

export const userReducer = (state = initialState, action) => {
    switch (action.type){
        case types.setUserInfo:
            return {
                userInfo: action.payload.userInfo
            }
        case types.updateUserInfo:
            return {
                userInfo: action.payload.userInfo
            }
        case types.logout:
            return {
                userInfo: null
            }
        default: 
            return state
    }
}