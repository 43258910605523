import { useQuery } from "@apollo/client"
import { GET_INDICE_BY_TITULO, GET_TITULO } from "graphql/Queries/queries.commons"
import { useState, useEffect } from "react"

export const useBook = (idBook) => {
    const [book, setBook] = useState({})
    const {data, loading, error } = useQuery(GET_TITULO,{
        variables: {
            _id: idBook
        }
    })
    useEffect(() => {
        if(data){
            setBook(data.getTituloByIdRegister)
        }
    } , [data])
    return {book, loading, error}
}

export const useIndice = (idBook) => {
    const [indice, setIndice] = useState({})
    const {data, loading: loadingIndice, error: errorindice } = useQuery(GET_INDICE_BY_TITULO,{
        variables: {
            _idTitulo: idBook
        }
    })

    useEffect(() => {
        if(data){
            setIndice(data.getIndiceByTituloOrAcademia?.[0])
        }
    } , [data])

    return {indice, loadingIndice, errorindice}
}
