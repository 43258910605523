import { MathJaxContext } from 'better-react-mathjax'
import { LatexDisplay } from 'components/common/LatexDisplay/LatexDisplay'
import useSettings from 'hooks/useSettings'


const config = {
    "fast-preview": {
        disabled: true
    },
    tex2jax: {
        inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
        ],
        displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
        ]
    },
    messageStyle: "none"
};

export default function QuestionBox({ numQuestion = 0, maxQuestions = 0, resource = false, questionText = '', maxAnswers = 1 }) {

    const [getEnvDomainResources] = useSettings()

    return (
        <MathJaxContext
            version={2}
            config={config}
            onStartup={(mathJax) => (mathJax.Hub.processSectionDelay = 0)}
        >

            <div className="component__questionBox">
                <div className="size-questions">
                    <span>{`Pregunta ${numQuestion}/${maxQuestions}`}</span>
                    <span className="">Opciones disponibles: {maxAnswers}</span>
                </div>
                <div className="title">
                    <LatexDisplay
                        text={questionText}
                    />
                    {/* <h3>{questionText}</h3> */}
                    <div className="img">{resource.length > 0 && <img src={getEnvDomainResources() + resource} alt="Recurso de pregunta" />}</div>
                </div>
            </div>
        </MathJaxContext>
    )
}
