import { gql } from '@apollo/client'

export const GET_EJERCICIO_BY_ID = gql`
    query getEjercicioById($id: ID!) {
        getEjercicioById(
            _id: $id
        )
        {
            _id,
            tiempoS,
            tipo{
                _id, 
                name
            },
            nombre,
            numero,
            cuerpo{
            ... on Memorama {_id, palabra,
                        recursos {_id, nombre, tipo, url} 
                    }
            ... on Quiz {_id, pregunta,
                        recursos {_id, nombre, tipo, url},
                        respuestas {_id, respuesta, correcta,
                    recursos {_id, nombre, tipo, url}
                    }
                    }
            ... on Ahorcado {_id, pregunta, respuesta}
            ... on RelCol {_id, columna1, columna2
                        recursos {_id, nombre, tipo, url}
                    }
            }
        }
    }
`


export const GET_ALL_EXERCISES_BY_INDICE = gql`
    query getEjerciciosByIndice($_idIndice: ID!) {
        getEjerciciosByIndice(_idIndice: $_idIndice) {
            _id
            titulo
            tipo{
                name
            }
            numero
            nombre
            tiempoS
        }
    }
`