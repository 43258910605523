import React from 'react';
//Router
import { AppRouter } from 'routers/AppRouter';

//Redux
import { Provider } from 'react-redux';
import { persistor, store } from 'redux/store/store';
import { PersistGate } from 'redux-persist/integration/react';



export const App = () => {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<AppRouter />
			</PersistGate>
		</Provider>
	);
};
