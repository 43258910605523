import React from 'react'

import PDF from 'assets/images/icons/pdf.png'
import Popup from 'reactjs-popup'
import { ViewerPDF } from 'components/Helpers/ViewerPDF/ViewerPDF'

export const Recurso = ({recurso}) => {

    const {nombre, esDescargable, url} = recurso

    return (
        <div className="component__itemRecurso">
            <div className="icon">
                <img src={PDF} alt="" />
            </div>
            <div className="content-item">
                <div className="description">
                    <div className="title">{nombre}</div>
                </div>
                <div className="options-item">
                    <OptionsItem esDescargable={esDescargable} urlRecurso={url} nombre={nombre}/>
                </div>
            </div>
        </div>
    )
}

const OptionsItem = ({esDescargable, urlRecurso, nombre}) => {
    return (
        <div className="buttons">
            {
                esDescargable ? <a 
                download={nombre}
                href={process.env.REACT_APP_DOMAIN_RESOURCE+urlRecurso}
            >
                Descargar
            </a>
                : <Popup className="modal" trigger={<button className="view">Visualizar</button>} modal position="right center">
                    { close => (<ViewerPDF urlRecurso={urlRecurso} close={close}/>)}
                </Popup>
            }
        </div>

    )
}


