import { types } from "types/types"

const initialState = {
    idPortEvids: null
}

export const portEvidsReducer = (state = initialState, action) => {
    switch(action.type){
        case types.selectedPortEvids:
        return{
            idPortEvids: action.payload.idPortEvids
        }
        case types.logout:
        return{
            idPortEvids: null
        }
        default:
            return state
    }
}