
//Icons
import { FiAlertOctagon, FiCoffee } from "react-icons/fi"
import { MdTimer } from "react-icons/md"


export const useContentText = (isTimer, isResource) => {
    
    let state = {
        title: '¡Estas apunto de comenzar con el ejercicio!',
        icon: <FiCoffee />,
        message: 'Preparate para contestar correctamente el siguiente ejercicio.',
        buttonText: 'Comenzar ahora',
    }
    
    if(isTimer) {
        state = {
            title: '¡El ejercicio tendrá limite de tiempo!',
            icon: <MdTimer />,
            message: 'Antes de iniciar el ejercicio considera el tiempo que tienes para contestar.',
            buttonText: 'Comenzar'
        }
    }

    if(isResource) {
        state = {
            title: '¡Tomate el tiempo de ver el recurso del ejercicio!',
            icon: <FiCoffee />,
            message: 'Antes de iniciar el ejercicio considera abrir el recurso disponible.',
            buttonText: 'Abrir recurso',
            
        }
    }

    if(isTimer && isResource) {
        state = {
            title: '¡El ejercicio tendrá limite de tiempo y un recurso disponible!',
            icon: <FiAlertOctagon />,
            message: 'Antes de iniciar el ejercicio abre el recurso que debes visualizar y comienza a contestar.',
            buttonText: 'Comenzar' 
        }
    }


    return { state }

}


export const useIdURLYoutube = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    const id = (match&&match[7].length === 11) ? match[7] : false

    return { id }
}