import { useState } from "react"

export const useCalificacion = (data = []) => {
    const [stateCalificacion, setStateCalificacion] = useState(0)

    const arraysEqual = (a, b) => {
        if (a === b) return true;
        if (a == null || b == null) return false;
        /* if (a.length !== b.length) return false; */

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    const setCalificaciones = (answers) => {
        
        let sumCalif = 0
        if (data && answers) {
            const respuestasCorrecta = data.map(e => {
                let resp = []
                if (e.respuestas.map(e => {
                        if (e.correcta === true) resp.push(e._id)
                    })) return resp
            })

            for (let index = 0; index < respuestasCorrecta.length; index++) {
                const eAnswers = answers[index]
                
                for (let index = 0; index < respuestasCorrecta.length; index++) {
                    const eAnswersCorrects = respuestasCorrecta[index]
                    
                    if (arraysEqual(eAnswers, eAnswersCorrects)) {
                        sumCalif += 10
                    }
                }
            }
            return sumCalif / data.length
        }
        return 0
    }

    return [setCalificaciones]

}