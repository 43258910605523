import { MathJax } from "better-react-mathjax"
export const LatexDisplay = ({ text }) => {

	return (
		<div style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '100%'
		}}>
			<MathJax
				hideUntilTypeset={"first"}
				inline
				dynamic
			>
				{text}
			</MathJax>
		</div>
	)
}