import React from 'react'

import useSettings from 'hooks/useSettings'
import ReactPlayer from 'react-player'
import { ViewerPDF } from 'components/Helpers/ViewerPDF/ViewerPDF'
import { LatexDisplay } from './LatexDisplay'

export const MostrarRecurso = ({ type, url, latex, close }) => {

    const [getEnvDomainResources] = useSettings()

    if (type === 'pdf') return <ViewerPDF urlRecurso={url} close={close} />

    return (
        <div className="custom__modal w768 h500">
            <div className="title">
                <div className="text">
                    <h2>Recurso del ejercicio</h2>
                </div>
                <button className="close" onClick={close}>
                    &times;
                </button>
            </div>
            <div className="content">
                {type === 'youtube' && <ReactPlayer url={url} width={'100%'} />}
                {type === 'image' && <div className="wrapper__img-resource"><img src={getEnvDomainResources() + url} alt="Img de recurso" /></div>}
                {type === 'latex' && <LatexDisplay text={latex} />}
            </div>
        </div>
    )
}
