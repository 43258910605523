import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
//Components
import { Indice } from './Indice'
import { ListAlumnos } from './ListAlumnos'
import { LoaderSection } from 'components/Helpers/Loader/Loader'
import { HelpComponent } from 'components/Helpers/HelpComponent'

//Redux
import { useDispatch, useSelector } from 'react-redux'
//GraphQL
import { useQuery } from '@apollo/client'
import { GET_CALIFICACION_BY_GRUPO, GET_MY_GRUPOS_BY_ACADEMIA } from 'graphql/Queries/queries.profesor'

import './styles.css'
import { setGroup } from 'redux/actions/actions'

export const Alumnos = () => {

    const dispatch = useDispatch()

    const [alumnos, setAlumnos] = useState([])
    const [grupos, setGrupos] = useState([])
    const [idTema, setIdTema] = useState('')
    const { idAcademia } = useSelector(state => state.academia)
    const { idGroup } = useSelector(state => state.group)

    const { data: getMyGruposByAcademia } = useQuery(GET_MY_GRUPOS_BY_ACADEMIA, {
        variables: {
            _idAcademia: idAcademia
        },
        onCompleted: () => {
            dispatch(setGroup(''))
        }
    })
    const { data: getCalificacionByGrupo, loading, error } = useQuery(GET_CALIFICACION_BY_GRUPO, {
        variables: {
            _idGrupo: idGroup,
            _idIndice: idTema
        },
        skip: idTema === ''
    })

    useEffect(() => {
        if (getMyGruposByAcademia) setGrupos(getMyGruposByAcademia.getMyGruposByAcademia)
    }, [getMyGruposByAcademia])
    
    useEffect(() => {
        if (getCalificacionByGrupo) setAlumnos(getCalificacionByGrupo.getcalificacionbyGrupo)
    }, [getCalificacionByGrupo])

    if (!idAcademia) return <LoaderSection />

    const handleGrupSelect = (e) => {
        dispatch(setGroup(e.target.value))
    }

    return (
        <div className="component__alumnos">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Alumnos - Ktdra Digital</title>
            </Helmet>
            <div className="content_alumnos">
                <div className="indice">
                    <h3>Indice del libro <HelpComponent title="Indice" message="Selecciona el tema del libro para visualizar las calificaciones de tus alumnos." /></h3>
                    <Indice setIdTema={setIdTema} />
                </div>
                <div className="listAlumnos">
                    <div className="title">
                        <h3>Listado Alumnos <HelpComponent title="Indice" message="Selecciona un grupo y el tema para visualizar las calificaciones de tus alumnos." /></h3>
                        <div className="select__option">
                            <select onChange={handleGrupSelect}>
                                <option value="" disabled selected>Selecciona un grupo</option>
                                {
                                    grupos.map((grupo, index) => (<option key={index} value={grupo._id}>{grupo.nombre}</option>))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="content">
                        
                    </div>
                    { loading && <LoaderSection /> }
                    { (!loading && alumnos.length > 0) ? <ListAlumnos alumnos={alumnos} /> : null}        
                </div>
            </div>
        </div>
    )
}
