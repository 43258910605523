import React, {useState, useEffect}  from 'react'

import MemoCard from './MemoCard'

const Tablero = ({ data, correctos, setCorrectos }) => {
    
    const newData = []
    
    const [dataMemo, setDataMemo] = useState()
    const [openedCard, setOpenedCard] = useState([])
    const [matched, setMatched] = useState([])

    const pairOfWords = [...data, ...data];

    const [start, setStart] = useState(false);

    function flipCard(index){
        setOpenedCard((opened) => [...opened, index]);
    }

    useEffect(()=>{
        pairOfWords.map((carta) => {
             let number = Math.floor((Math.random() * ((0 + pairOfWords.length))));
             const imgCarta = carta.recursos[0] ? carta.recursos[0] : false

             while (newData[number]){
                 number = Math.floor((Math.random() * ((0 + pairOfWords.length))));
             }
 
             newData[number] = {
                 _id: carta._id,
                 palabra: carta.palabra,
                 imgCarta: imgCarta
             }
        })
        if(newData.length === pairOfWords.length){
            setDataMemo(newData)
            setStart(true)  
        }
    }, [])

    useEffect(()=>{
        if(openedCard.length < 2) return false
        const firstMatched = dataMemo[openedCard[0]]
        const secondMatched = dataMemo[openedCard[1]]

        if(secondMatched && firstMatched._id === secondMatched._id && openedCard.length === 2){
            setMatched([...matched, firstMatched._id]);
            //dataScore(correctos + 1)
            setCorrectos(correctos+1)
        }

        if(openedCard.length === 2){
            setTimeout(()=> setOpenedCard([]),500)
        }
    }, [openedCard])


    return (
        <>
            <div className="cards">
                {
                    start ?
                        dataMemo.map((word,index)=>{
                            let isFlipped = false
                            const classImg = word.imgCarta ? 'with-img' : ''
                            if(openedCard.includes(index)) isFlipped = true;
                            if(matched.includes(word._id)) isFlipped = true;

                            return ( 
                                <div
                                    className={`pokemon-card ${classImg} ${isFlipped ? "flipped" : ""}`}
                                    key={index}
                                    onClick={!isFlipped ? () => flipCard(index): null}
                                >
                                    <MemoCard data={word}/>
                                </div>
                            )
                        })
                    : 
                        <div>cargando</div>
                }
            </div>
        </>
    ) 
}

export default Tablero 