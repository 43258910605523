import React from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import Popup from 'reactjs-popup';
import { ViewerPDF } from 'components/Helpers/ViewerPDF/ViewerPDF';
import 'react-accessible-accordion/dist/fancy-example.css';

export const Book = ({ libro }) => {
	const { hijos: unidades } = libro;
	return (
		<div>
			<Accordion allowZeroExpanded className='accordion__book'>
				{unidades?.map(unidad => {
					const { hijos: temas } = unidad;
					return (
						<AccordionItem key={unidad._id}>
							<AccordionItemHeading>
								<AccordionItemButton>
									{`${unidad.seccion} ${unidad.nombre}`}
								</AccordionItemButton>
							</AccordionItemHeading>
							{temas.map(tema => (
								<AccordionItemPanel key={tema._id}>
									<div>{`${tema.seccion} ${tema.nombre}`}</div>
									<div className='buttons'>
										<Popup
											className='modal'
											trigger={
												tema.recursos[0]?.url === undefined ? (
													<span></span>
												) : (
													<button className='view'>Visualizar</button>
												)
											}
											modal
											position='right center'
										>
											{close => (
												<ViewerPDF urlRecurso={tema.recursos[0]?.url} close={close} />
											)}
										</Popup>
									</div>
								</AccordionItemPanel>
							))}
						</AccordionItem>
					);
				})}
			</Accordion>
		</div>
	);
};
