import React from 'react'
import { LoaderPage } from 'components/Helpers/Loader/Loader'
import { useParams } from 'react-router-dom'
import { ContentEjercicio } from './ContentEjercicio'
import { useEjercicio } from './hooks'
import './styles.css'

export const Testing = () => {

    const { token } = useParams()
    const [dataEjercicio, loadingEjercicio, errorEjercicio] = useEjercicio(token)

    if(loadingEjercicio) return <LoaderPage/>
    if(errorEjercicio) return null

    return (
        <div className="component__testing">
            <div className="header">
                <h2>Bienvenido al ambiente de pruebas de Ktdra Digital</h2>
            </div>
            <ContentEjercicio data={dataEjercicio?.cuerpo} type={dataEjercicio.tipo?.name} />
        </div>
    )
}
