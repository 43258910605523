import React from 'react'
import CardPregunta from './CardPregunta'

const WrapperPreguntas = ({ data = [], identidadRef }) => {

    return (
        <div className="wrapper__column">
            <div className="title">
                <span>Preguntas</span>
            </div>
            <div className="cards">
                { 
                    data.map((pregunta, index) => {
                    return <CardPregunta key={index} pregunta={pregunta} id={index} identidadRef={identidadRef}/>
                })}
            </div>
        </div>
    )
}

export default WrapperPreguntas
