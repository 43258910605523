import React from 'react'
import { useHistory } from 'react-router'
import { CircularProgressbar } from 'react-circular-progressbar'
//Redux
import { setPortEvids, setTema} from 'redux/actions/actions'
import { useDispatch } from 'react-redux'

import 'react-circular-progressbar/dist/styles.css'
import { Tooltip } from 'components/Helpers/Tooltip/Tooltip'
import { RiArrowRightSLine } from 'react-icons/ri'

export const Libro = ({ data }) => {   
    const PORTADA_IMG = `${process.env.REACT_APP_DOMAIN_RESOURCE}${data?.titulo?.portada}`
    const history = useHistory()
    const dispatch = useDispatch()

    const handleHistoryBook = (_id) => {
        dispatch(setPortEvids(_id))
        dispatch(setTema(''))
        history.replace('/alumno/libro')
    }

    return (
        <div className={data?.estatus === true ? "component__libro" : "component__libro disabled"}>
            <div className="title noselect">
                <h2>{data?.titulo?.nombre ?? ''}</h2>
                <span>{data?.titulo?.autor ?? ''}</span>
            </div>
            <div className="content">
                <div className="cicle_porcentage">
                    <Tooltip
                        content={'Porcentaje de avance del libro.'}
                        onAction={['hover']}
                        trigger={<div><CircularProgressbar value={data?.avance ?? 0} maxValue={100} text={`${data?.avance ?? 0 }%`} /></div>} />
                </div>
                <div className="book_image noselect">
                    <img src={PORTADA_IMG} alt="Portada libro" className="noselect" />
                </div>
            </div>
            <div className="footer">
                <div className="description noselect">
                    <span>Grupo {data?.grupo ?? ''}</span>
                    <span>{data?.profesor ?? ''}</span>
                </div>
                <div className="arrow_button">
                    { data?.estatus === true ? <button onClick={() => handleHistoryBook(data?._id ?? '', data?.temasOnProces)}>Continuar <RiArrowRightSLine /></button> : <span>Desactivado</span> }
                </div>
            </div>
        </div>
    )
}
